import jwt from "jwt-decode";
import { Token } from "../../core/types/types";
import { FiChevronRight, FiUserPlus } from "react-icons/fi";
import { Link, useNavigate } from "react-router-dom";
import logo from "../../assets/logo.svg";
import { ColorsEnum, VariantsEnum, SizesEnum } from "../../core/enums/enums";
import { Button } from "../button";
import { DropDownMenu } from "../dropdown/dropdown-menu";
import HeaderMenu from "./header.menu";
import { PiUserCircleLight } from "react-icons/pi";
import { isExpired } from "react-jwt";
import PlatformsMenu from "./platforms.menu";

interface HeaderDesktopProps {
  handleLogOut: () => void;
}

export default function HeaderDesktop({ handleLogOut }: HeaderDesktopProps) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("access_token");
  const isLoggedIn = token ? !isExpired(token) : false;

  return (
    <>
      <div className="mx-auto my-auto flex w-full items-center justify-between lg:max-w-7xl md:max-w-sm">
        <div className="flex w-full">
          <div className="flex items-center gap-8">
            <div className="flex h-full items-center justify-center">
              <img
                src={logo}
                alt="Tornado GI"
                className="h-auto w-11 cursor-pointer"
                loading="lazy"
                onClick={() => navigate("/")}
              />
            </div>

            <DropDownMenu listChildren={<PlatformsMenu />}>
              <p className="text-[#8C9AA3] text-sm lg:block hidden hover:font-bold font hover:text-[#0C6BAF] transition-all duration-200 border-b-2 border-b-transparent hover:border-b-[#19D0DD]">
                Platforms
              </p>
            </DropDownMenu>

            <Link
              to={"/opportunities"}
              className="text-[#8C9AA3] text-sm lg:block hidden hover:font-bold font hover:text-[#0C6BAF] transition-all duration-200 border-b-2 border-b-transparent hover:border-b-[#19D0DD]"
            >
              Opportunities
            </Link>

            <Link
              to={"/contact-us"}
              className="text-[#8C9AA3] text-sm lg:block hidden hover:font-bold font hover:text-[#0C6BAF] transition-all duration-200 border-b-2 border-b-transparent hover:border-b-[#19D0DD]"
            >
              Contact Us
            </Link>
          </div>
        </div>
        <div className="flex w-full items-center justify-end gap-4">
          {isLoggedIn && token ? (
            <>
              <DropDownMenu
                listChildren={<HeaderMenu handleLogOut={handleLogOut} />}
              >
                <Button
                  color={ColorsEnum.Muted}
                  variant={VariantsEnum.Outline}
                  size={SizesEnum.Small}
                  type="button"
                  className="font-bold !rounded-3xl w-fit"
                >
                  <div className="flex items-center gap-2 justify-center">
                    <PiUserCircleLight size={20} />
                    <span>{(jwt(token) as Token).first_name}</span>
                    <FiChevronRight size={20} />
                  </div>
                </Button>
              </DropDownMenu>
            </>
          ) : (
            <div className="flex w-fit items-center justify-center gap-4">
              <Button
                color={ColorsEnum.Muted}
                variant={VariantsEnum.Muted}
                size={SizesEnum.Medium}
                onClick={() => navigate("/login")}
                className="font-bold !rounded-3xl"
              >
                <div className="flex items-center gap-1 justify-center">
                  <span>Login</span>
                  <FiUserPlus />
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
