import { DirectionEnum } from "../../core/enums/enums";

interface DropDownMenuButtonItemProps {
  action?: () => void;
  label: string;
  icon?: JSX.Element;
  labelColorClass: string;
  backgroundColorClass?: string;
  backgroundHoverColorClass?: string;
  disabled?: boolean;
  dir?: DirectionEnum;
}

export const DropDownMenuButtonItem = ({
  action,
  label,
  labelColorClass = "text-primary",
  backgroundColorClass = "bg-transparent",
  backgroundHoverColorClass = "hover:bg-slate-500",
  icon,
  disabled,
  dir = DirectionEnum.RTL,
}: DropDownMenuButtonItemProps) => {
  return (
    <button
      onClick={action}
      className={`flex w-full items-center justify-start gap-2 rounded-lg p-2 text-sm font-light ${backgroundColorClass} ${labelColorClass} transition-all duration-200 ${backgroundHoverColorClass} hover:bg-opacity-10 ${
        disabled ? "cursor-not-allowed" : "cursor-pointer"
      }`}
      disabled={disabled}
      dir={dir}
    >
      {Boolean(icon) && icon}
      <span>{label}</span>
    </button>
  );
};

export default DropDownMenuButtonItem;
