import { FundingOpportunity } from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import SharedPagination from "../pagination/pagination";
import {
  ColorsEnum,
  SizesEnum,
  Status,
  VariantsEnum,
} from "../../core/enums/enums";
import { FiChevronLeft, FiChevronRight, FiPlus } from "react-icons/fi";
import { useNavigate } from "react-router-dom";
import { Button } from "../button";
import { BiShareAlt } from "react-icons/bi";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import nihLogo from "../../assets/nih-logo.svg";
import {
  fetchOpportunityListService,
  updateOpportunityVisibilityService,
} from "../../core/services/shared.service";
import useIsMobileScreen from "../../core/hooks/use-is-mobile-screen.helpers";

export default function OpportunitiesList() {
  const navigate = useNavigate();
  const [opportunities, setOpportunities] = useState<FundingOpportunity[]>([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelected] = useState<string[]>([]);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [activeSelectedOpportunity, setActiveSelectedOpportunity] =
    useState<FundingOpportunity | null>(null);
  const [didCopy, setDidCopy] = useState<boolean>(false);
  const { isMobile } = useIsMobileScreen();

  const limit = 10;

  const fetchOpportunities = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchOpportunityListService({
        limit: limit,
        offset: limit * (currentPage - 1),
      });

      setOpportunities(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  }, [currentPage]);

  const updateSelectedItems = (id: string) => {
    setSelected((oldList) => {
      if (oldList.includes(id)) {
        return [...oldList.filter((i) => i !== id)];
      }
      return [...oldList, id];
    });
  };

  const ToggleVisibility = async (
    opportunities_ids: string[],
    status: string
  ) => {
    setLoading(true);
    try {
      await updateOpportunityVisibilityService({
        opportunities_ids: opportunities_ids,
        status: status,
      });

      await fetchOpportunities();
    } catch (error) {
      toast_error(error);
    }
    setSelected([]);
  };

  const copyLink = () => {
    setDidCopy(true);
    navigator.clipboard.writeText(
      `${process.env["REACT_APP_FRONTEND_BASE_URL"]}/opportunities/${activeSelectedOpportunity?.id}`
    );
    setTimeout(() => {
      setDidCopy(false);
    }, 1000);
  };

  useEffect(() => {
    fetchOpportunities();
  }, [fetchOpportunities]);

  if (loading)
    return (
      <div className="w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  if (opportunities.length === 0) {
    return <div className="w-full text-center">No Data</div>;
  }

  return (
    <div className="w-full">
      <div className="flex gap-4 flex-col mb-6">
        <div className="flex items-center justify-between w-full">
          <p className="text-black font-bold lg:text-4xl">Opportunities</p>
          {selected.length > 0 ? (
            <div className="flex items-center justify-end gap-2">
              <Button
                color={ColorsEnum.Danger}
                variant={VariantsEnum.Outline}
                size={isMobile ? SizesEnum.Small : SizesEnum.Large}
                className="font-bold !rounded-3xl"
                onClick={() => ToggleVisibility(selected, Status.Hidden)}
              >
                Hide
              </Button>
              <Button
                color={ColorsEnum.Success}
                variant={VariantsEnum.Filled}
                size={isMobile ? SizesEnum.Small : SizesEnum.Large}
                className="font-bold !rounded-3xl"
                onClick={() => ToggleVisibility(selected, Status.Visible)}
              >
                Show
              </Button>
            </div>
          ) : (
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={isMobile ? SizesEnum.Small : SizesEnum.Medium}
              onClick={() => navigate("/dashboard/opportunities/create")}
              className="font-bold !rounded-3xl"
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <FiPlus />
                <span>Create New</span>
              </div>
            </Button>
          )}
        </div>
        <div className="text-[#64748B] font-bold text-sm">
          Result : {totalCount}
        </div>

        {opportunities.map((e, i) => (
          <div
            className="flex gap-2 items-center w-full"
            key={`opportunities-${i}`}
          >
            <div>
              <input
                className="h-6 w-6"
                type={"checkbox"}
                defaultChecked={selected.includes(e.id)}
                onClick={() => {
                  updateSelectedItems(e.id);
                }}
              />
            </div>
            <div
              className="rounded-2xl group cursor-pointer w-full flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
              key={e.id}
            >
              <div className="grid grid-cols-5 items-center gap-2">
                <p className="col-span-3 line-clamp-2 font-bold text-[#272D31] text-xs group-hover:text-[#0C6BAF]">
                  {e.title}
                </p>
                <p className="text-[#272D31] text-xs font-bold text-end">
                  {new Date(e.published).toDateString()}
                </p>
                <div
                  className={`text-[10px] text-center ml-auto px-2 py-1 w-fit bg-opacity-10 font-bold rounded-2xl ${
                    e.status === Status.Visible
                      ? "text-[#0BBB87] bg-[#0BBB87]"
                      : "text-[#E61010] bg-[#E61010]"
                  }`}
                >
                  {e.status}
                </div>
              </div>

              <div className="flex w-full items-center justify-between h-12">
                <div className="flex items-center gap-2">
                  <div className="text-[#64748B] text-xs w-fit flex gap-2 items-center">
                    <img src={nihLogo} alt="" className="h-5 w-auto" />
                    <span>U.S. NIH Platform</span>
                  </div>
                  {e.type !== "NORMAL" && (
                    <div className="bg-[#01487B] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#0C6BAF] text-[10px]">
                      {e.type}
                    </div>
                  )}
                  {e.tags?.map((e) => (
                    <p
                      key={`${e}`}
                      className="bg-[#BC5C25] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#E96215] text-[10px]"
                    >
                      {e}
                    </p>
                  ))}
                </div>
                <div className="hidden group-hover:block">
                  <Button
                    color={ColorsEnum.Primary}
                    variant={VariantsEnum.Filled}
                    size={SizesEnum.Small}
                    className="font-bold !rounded-3xl"
                    onClick={() => setActiveSelectedOpportunity(e)}
                  >
                    <div className="flex items-center gap-1 justify-center">
                      <span>View </span>
                      <FiChevronRight />
                    </div>
                  </Button>
                </div>
              </div>
            </div>
          </div>
        ))}

        <div className="w-full mx-auto mb-4">
          <SharedPagination
            limit={limit}
            totalListCount={totalCount}
            onPageClick={(page: number) => {
              setCurrentPage(page);
            }}
            currentPage={currentPage}
          />
        </div>

        {activeSelectedOpportunity && (
          <>
            {/* <!--Overlay Effect--> */}
            <div className="z-[9999] fixed inset-0 min-h-full overflow-hidden bg-white/5 backdrop-blur-md" />
            <div className="z-[9999] fixed inset-0 flex h-full w-full items-center justify-center overflow-hidden bg-gray-600 bg-opacity-50">
              <div className="z-[9999] absolute right-0 h-screen w-10/12 lg:w-1/3 lg:p-12 p-6 overflow-x-hidden rounded-l-xl bg-white">
                <div className="flex items-center mb-4 justify-between w-full gap-3">
                  <Button
                    color={ColorsEnum.Muted}
                    variant={VariantsEnum.Muted}
                    size={SizesEnum.Medium}
                    onClick={() => setActiveSelectedOpportunity(null)}
                    className="font-bold !rounded-3xl"
                  >
                    <div className="flex items-center gap-1 justify-center">
                      <FiChevronLeft />
                      <span>Back</span>
                    </div>
                  </Button>

                  <Button
                    color={ColorsEnum.Primary}
                    variant={VariantsEnum.Filled}
                    size={SizesEnum.Medium}
                    onClick={() =>
                      navigate(
                        `/dashboard/opportunities/${activeSelectedOpportunity.id}/edit`
                      )
                    }
                    className="font-bold !rounded-3xl"
                  >
                    <div className="flex items-center gap-1 justify-center">
                      <span>Edit</span>
                      <PiPencilSimpleLineBold />
                    </div>
                  </Button>
                </div>
                <div className="flex items-center justify-between w-full">
                  <div className="text-[#64748B] flex-shrink-0 text-xs w-fit flex gap-2 items-center">
                    <img src={nihLogo} alt="" className="h-5 w-auto" />
                    <span>U.S. NIH Platform</span>
                  </div>
                  <div className="flex gap-2 mb-7 h-9 items-center justify-end w-full">
                    {activeSelectedOpportunity.type !== "NORMAL" && (
                      <div className="bg-[#01487B] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#0C6BAF] text-[10px]">
                        {activeSelectedOpportunity.type}
                      </div>
                    )}
                    {activeSelectedOpportunity.tags?.map((e) => (
                      <p
                        key={`${e}`}
                        className="bg-[#BC5C25] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#E96215] text-[10px]"
                      >
                        {e}
                      </p>
                    ))}
                  </div>
                </div>

                <h1 className="text-2xl font-bold text-black">
                  {activeSelectedOpportunity?.title}
                </h1>

                <div className="my-7 w-full flex justify-end items-center">
                  <Button
                    color={ColorsEnum.Muted}
                    variant={VariantsEnum.Muted}
                    size={SizesEnum.Small}
                    className="font-bold"
                    onClick={copyLink}
                  >
                    {didCopy ? (
                      "Copied"
                    ) : (
                      <div className="flex items-center gap-1 justify-center">
                        <span>Share</span>
                        <BiShareAlt />
                      </div>
                    )}
                  </Button>
                </div>

                <div className="w-full h-px bg-[#0E273A] bg-opacity-10" />

                <p className="text-base text-black font-bold my-6">
                  {activeSelectedOpportunity?.description}
                </p>

                {activeSelectedOpportunity?.eligibility?.map((val, index) => (
                  <p
                    key={val.text}
                    className={`${
                      val.type === "header" ? "my-4 font-bold" : ""
                    } ${index === 1 && "text-blue-800 font-bold mt-4"}`}
                  >
                    {val.text}
                  </p>
                ))}

                <div className="flex items-start justify-end gap-3">
                  <p className="text-[#64748B] text-sm">
                    For more details you can
                  </p>
                  <a
                    href={activeSelectedOpportunity?.link}
                    target="_blank"
                    rel="noreferrer"
                    className="text-[#065FE6] flex items-center gap-1 justify-center text-sm font-bold"
                  >
                    <span>Visit the Website</span>
                    <FiChevronRight />
                  </a>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}
