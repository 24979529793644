import SharedPaginationButtons from "./pagination_buttons";
import SharedPaginationCount from "./pagination_count";


type SharedPaginationProps = {
  currentPage: number;
  limit: number;
  totalListCount: number;
  onPageClick: (page: number) => void;
};

const scrollToTop = () => {
  window.scrollTo(0, 0);
};

export function SharedPagination({
  currentPage,
  limit,
  totalListCount,
  onPageClick,
}: SharedPaginationProps) {
  if (totalListCount < 1) {
    return null;
  }

  if (totalListCount <= limit) {
    return <div></div>;
  }

  return (
    <div className="grid w-full grid-cols-1 items-center gap-6 sm:flex sm:justify-between sm:gap-0">
      <SharedPaginationCount
        limit={limit}
        totalListCount={totalListCount}
        currentPage={currentPage}
      />
      <SharedPaginationButtons
        pagesCount={Math.ceil(totalListCount / limit)}
        currentPage={currentPage}
        onPageClick={(page) => {
          scrollToTop();
          onPageClick(page);
        }}
      />
    </div>
  );
}

export default SharedPagination;
