export const baseURL = process.env["REACT_APP_BACKEND_BASE_URL"];

type URLs = {
  shared: {
    login: string;
    signup: string;
    forgetPassword: string;
    resetPassword: string;
    getUsersByAdmin: string;
    getAdminHistory: (userId: string) => string;
    updateUser: string;
    getUser: string;
    getUserInterest: (userId: string) => string;
    giveAdminPermission: string;
    getPublicOpportunityById: (opportunityId: string) => string;
    getOpportunityById: (opportunityId: string) => string;
    updateOpportunityById: (opportunityId: string) => string;
    countShareClick: (opportunitiesId: string) => string;
    addOpportunity: string;
    getFeedbackList: string;
    submitFeedback: string;
    getHistoryList: string;
    getAllCategories: string;
    createCategory: string;
    deleteCategory: (categoryId: string) => string;
    getAllOpportunity: string;
    getOpportunities: string;
    updateOpportunityVisibility: string;
    getUserInterests: string;
    userInterested: string;
    seed: string;
  };
};

// Export an object that contains all the app's URLs
export const urls: URLs = {
  shared: {
    login: `${baseURL}/user/token`,
    signup: `${baseURL}/user/sign-up`,
    forgetPassword: `${baseURL}/user/forget_password`,
    resetPassword: `${baseURL}/user/reset_password`,
    getUsersByAdmin: `${baseURL}/user`,
    getAdminHistory: (userId: string) => `${baseURL}/history/${userId}`,
    getUser: `${baseURL}/user/info`,
    updateUser: `${baseURL}/user`,
    getUserInterest: (userId: string) => `${baseURL}/user/interest/${userId}`,
    giveAdminPermission: `${baseURL}/user/admin-permission`,
    getPublicOpportunityById: (opportunityId: string) =>
      `${baseURL}/funding/${opportunityId}/public`,
    getOpportunityById: (opportunityId: string) =>
      `${baseURL}/funding/${opportunityId}`,
    updateOpportunityById: (opportunityId: string) =>
      `${baseURL}/funding/${opportunityId}`,
    countShareClick: (opportunityId: string) =>
      `${baseURL}/share/${opportunityId}`,
    addOpportunity: `${baseURL}/funding`,
    getOpportunities: `${baseURL}/funding`,
    getFeedbackList: `${baseURL}/feedback`,
    submitFeedback: `${baseURL}/feedback`,
    getHistoryList: `${baseURL}/history`,
    getAllCategories: `${baseURL}/category`,
    createCategory: `${baseURL}/category`,
    deleteCategory: (categoryId: string) => `${baseURL}/category/${categoryId}`,
    getAllOpportunity: `${baseURL}/funding/all`,
    updateOpportunityVisibility: `${baseURL}/funding/visibility`,
    getUserInterests: `${baseURL}/user/my-interests`,
    userInterested: `${baseURL}/user/interested`,
    seed: `${baseURL}/seed`,
  },
};
