import { useEffect, useState } from "react";
import { InputField } from "../input-field/input-field";
import { DirectionEnum } from "../../core/enums/enums";
import { PiMagnifyingGlass } from "react-icons/pi";

export interface QuickSearchInputProps {
  value: string | undefined;
  onSearchValueChange: (value: string) => void;
}

export function QuickSearchInput({
  value,
  onSearchValueChange,
}: QuickSearchInputProps) {
  const [debouncedTerm, setDebouncedTerm] = useState<string | undefined>(value);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (debouncedTerm !== undefined) {
        onSearchValueChange(debouncedTerm);
      }
    }, 1000);

    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedTerm]);

  useEffect(() => {
    setDebouncedTerm(value);
  }, [value]);

  return (
    <div className="relative lg:min-w-[340px]">
      <InputField
        className="!bg-[#EDF0F2]"
        type="text"
        value={debouncedTerm ?? ""}
        placeholder="search here.."
        onChange={(e) => {
          setDebouncedTerm(e.target.value);
        }}
        dir={DirectionEnum.LTR}
        startIcon={<PiMagnifyingGlass />}
      />
    </div>
  );
}
export default QuickSearchInput;
