import create from "zustand";
import { CategoryType } from "../types/types";

export type SeedDataStoreType = {
  categories: CategoryType[];
  setCategories: (data: CategoryType[]) => void;
};

const useSeedDataStore = create<SeedDataStoreType>((set) => ({
  categories: [],
  setCategories: (data) => set({ categories: data }),
}));

export default useSeedDataStore;
