export enum VariantsEnum {
  Filled = "filled",
  Outline = "outline",
  Muted = "muted",
  Link = "link",
  White = "white",
  None = "none",
}

export enum SizesEnum {
  Small = "sm",
  Medium = "md",
  Large = "lg",
}

export enum ColorsEnum {
  Primary = "primary",
  Secondary = "secondary",
  Danger = "danger",
  Success = "success",
  Warning = "warning",
  Accent = "accent",
  Muted = "muted",
  White = "white",
}

export enum Status {
  Visible = "Visible",
  Hidden = "Hidden",
}

export enum Tags {
  INTERNATIONAL = "INTERNATIONAL",
  FOGARTY = "FOGARTY",
}

export enum AvailabilityEnum {
  AVAILABLE = "AVAILABLE",
  CLOSED = "CLOSED",
}

export enum DirectionEnum {
  LTR = "ltr",
  RTL = "rtl",
}

export enum ModalSizeEnum {
  XLARGE = "XLARGE",
  LARGE = "LARGE",
  MEDIUM = "MEDIUM",
  SMALL = "SMALL",
}
