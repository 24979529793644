import create from "zustand";
import { UserDataType } from "../types/types";

export type UserDataStoreType = {
  userData: UserDataType | null;
  setUserData: (data: UserDataType) => void;
  refreshUserData: boolean;
  setRefreshUserData: (refresh: boolean) => void;
};

const useUserDataStore = create<UserDataStoreType>((set) => ({
  userData: null,
  refreshUserData: false,
  setUserData: (data: UserDataType | null) => {
    set((state: UserDataStoreType) => ({
      ...state,
      userData: data,
    }));
  },
  setRefreshUserData: (refresh: boolean) => {
    set((state: UserDataStoreType) => ({
      ...state,
      refreshUserData: refresh,
    }));
  },
}));

export default useUserDataStore;
