import { useState, useEffect } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import jwt from "jwt-decode";
import { useNavigate } from "react-router-dom";
import { CreateOrUpdateOpportunityData, Token } from "../../core/types/types";
import Header from "../header/header";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  Tags,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom";
import ClipLoader from "react-spinners/ClipLoader";
import { useFormik } from "formik";
import { validationSchema } from "./create_or_edit_opportunity.validation";
import { InputField } from "../input-field/input-field";
import { TextArea } from "../textarea/textarea";
import { PiLinkSimple } from "react-icons/pi";
import { Select } from "../select/select";
import { Button } from "../button";
import {
  createOpportunityByIdService,
  fetchOpportunityByIdService,
  updateOpportunityByIdService,
} from "../../core/services/shared.service";

export default function CreateOrEditOpportunity() {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("access_token") ?? null;
  const { opportunity_id = "" } = useParams<{ opportunity_id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const isEditMode = opportunity_id !== "";

  const [data, setData] = useState<CreateOrUpdateOpportunityData>({
    title: "",
    description: "",
    link: "",
    published: "",
    due_date: "",
    tags: [],
  });

  const formik = useFormik({
    initialValues: data,
    onSubmit: () => {
      if (isEditMode) {
        update_opportunity();
        return;
      }
      create_opportunity();
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  useEffect(() => {
    if (token === null || !(jwt(token) as Token).is_admin) {
      navigate("/");
    }

    if (isEditMode) {
      setLoading(true);
      get_opportunity();
    }

    // eslint-disable-next-line
  }, []);

  const get_opportunity = async () => {
    try {
      const res = await fetchOpportunityByIdService(opportunity_id);
      setData(res);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  };

  const create_opportunity = async () => {
    try {
      await createOpportunityByIdService(formik.values);
      toast.success("Success");
      navigate("/dashboard");
    } catch (error) {
      toast_error(error);
    }
  };

  const update_opportunity = async () => {
    try {
      await updateOpportunityByIdService(opportunity_id, {
        tags: formik.values.tags,
      });
      toast.success("Success");
      navigate("/dashboard");
    } catch (error) {
      toast_error(error);
    }
  };

  if (loading)
    return (
      <div className="bg-white rounded-lg border w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />
        <div className="lg:max-w-7xl md:max-w-sm max-w-xs mx-auto w-full lg:mt-20">
          <h2 className="text-black font-bold text-4xl">
            {`${isEditMode ? "Edit Opportunity" : "Create New Opportunity"}`}
          </h2>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-10">
            <div className="bg-white rounded-[20px] p-8 w-full h-full">
              <InputField
                placeholder="Title"
                aria-label="Title"
                name="title"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                dir={DirectionEnum.LTR}
                value={formik.values.title.trimStart() ?? ""}
                isValid={formik.touched.title && !formik.errors.title}
                error={
                  formik.touched.title && formik.errors.title
                    ? formik.errors.title
                    : ""
                }
                disabled={isEditMode}
                readOnly={isEditMode}
              />
              <TextArea
                placeholder="Description"
                aria-label="Description"
                name="description"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                dir={DirectionEnum.LTR}
                value={formik.values.description.trimStart() ?? ""}
                isValid={
                  formik.touched.description && !formik.errors.description
                }
                error={
                  formik.touched.description && formik.errors.description
                    ? formik.errors.description
                    : ""
                }
                rows={20}
                disabled={isEditMode}
                readOnly={isEditMode}
              />
            </div>
            <div className="lg:w-[440px] w-full h-full flex flex-col gap-6 flex-shrink-0">
              <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
                <InputField
                  placeholder="Link"
                  aria-label="Link"
                  name="link"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  dir={DirectionEnum.LTR}
                  endIcon={<PiLinkSimple />}
                  value={formik.values.link.trimStart() ?? ""}
                  isValid={formik.touched.link && !formik.errors.link}
                  error={
                    formik.touched.link && formik.errors.link
                      ? formik.errors.link
                      : ""
                  }
                  disabled={isEditMode}
                  readOnly={isEditMode}
                />

                <InputField
                  placeholder="Published"
                  aria-label="Published"
                  type="date"
                  name="published"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  dir={DirectionEnum.LTR}
                  value={
                    formik.values.published
                      ? new Date(formik.values.published)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  isValid={formik.touched.published && !formik.errors.published}
                  error={
                    formik.touched.published && formik.errors.published
                      ? formik.errors.published
                      : ""
                  }
                  disabled={isEditMode}
                  readOnly={isEditMode}
                />

                <InputField
                  placeholder="Due Date"
                  aria-label="Due Date"
                  type="date"
                  name="due_date"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  dir={DirectionEnum.LTR}
                  value={
                    formik.values.due_date
                      ? new Date(formik.values.due_date)
                          .toISOString()
                          .split("T")[0]
                      : ""
                  }
                  isValid={formik.touched.due_date && !formik.errors.due_date}
                  error={
                    formik.touched.due_date && formik.errors.due_date
                      ? formik.errors.due_date
                      : ""
                  }
                />

                <Select
                  height={SizesEnum.Large}
                  listToDisplay={Object.values(Tags).map((e) => e)}
                  dir={DirectionEnum.LTR}
                  isValid={formik.touched.tags && !formik.errors.tags}
                  name="tags"
                  onChange={formik.handleChange}
                  value={formik.values.tags}
                  aria-label="Tag"
                />
              </div>
              {/* ACTIONS */}
              <div className="flex gap-4 justify-center p-8 bg-white w-full rounded-[20px]">
                <Button
                  color={ColorsEnum.Muted}
                  variant={VariantsEnum.Muted}
                  size={SizesEnum.Medium}
                  onClick={() => navigate("/dashboard")}
                  type="button"
                  className="font-bold !rounded-3xl !text-red-500"
                >
                  Cancel
                </Button>
                <Button
                  color={ColorsEnum.Success}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Medium}
                  type="submit"
                  className="font-bold !rounded-3xl !bg-success hover:!bg-success-600"
                >
                  {isEditMode ? "Update" : "Create"}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
