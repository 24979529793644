import {
  sharedPaginationEnglishStrings,
} from "./pagination_strings";

type SharedPaginationCountProps = {
  currentPage: number;
  limit: number;
  totalListCount: number;
};

export function SharedPaginationCount({
  currentPage,
  limit,
  totalListCount,
}: SharedPaginationCountProps) {
  const getLastShownRecordNumber = () => {
    if (limit * currentPage < totalListCount) {
      return limit * currentPage;
    }
    return totalListCount;
  };

  return (
    <div className="text-center text-gray-500 sm:mb-0 sm:text-right">
      {sharedPaginationEnglishStrings.countInPage(
        (currentPage - 1) * limit + 1,
        getLastShownRecordNumber(),
        totalListCount
      )}
    </div>
  );
}

export default SharedPaginationCount;
