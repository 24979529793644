import { toast_error } from "../../core/hooks/toast_error";
import { useNavigate, useParams } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { validationSchema } from "./reset-password-container.validation";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronRight } from "react-icons/fi";
import { resetPasswordService } from "../../core/services/shared.service";
import { useState } from "react";
import { PiEye, PiEyeClosed } from "react-icons/pi";

export default function ResetPasswordContainer() {
  const { token = "" } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean[]>([
    false,
    false,
  ]);

  const formik = useFormik({
    initialValues: {
      new_password: "",
      confirm_new_password: "",
    },
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      await resetPasswordService({ ...formik.values, token: token });

      toast("Your password has been changed!", {
        style: { backgroundColor: "#38a169", color: "white" },
      });
      navigate("/login");
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <p className="text-black font-bold text-4xl">Reset Password</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <InputField
            placeholder="**********"
            aria-label="New Password"
            type={isPasswordVisible[0] ? "text" : "password"}
            name="new_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[0] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([false, isPasswordVisible[1]])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([true, isPasswordVisible[1]])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.new_password.trimStart() ?? ""}
            isValid={formik.touched.new_password && !formik.errors.new_password}
            error={
              formik.touched.new_password && formik.errors.new_password
                ? formik.errors.new_password
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Confirm Password"
            type={isPasswordVisible[1] ? "text" : "password"}
            name="confirm_new_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[1] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([isPasswordVisible[0], false])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([isPasswordVisible[0], true])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.confirm_new_password.trimStart() ?? ""}
            isValid={
              formik.touched.confirm_new_password &&
              !formik.errors.confirm_new_password
            }
            error={
              formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password
                ? formik.errors.confirm_new_password
                : ""
            }
          />

          {/* ACTIONS */}
          <div className="flex  justify-end w-full">
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Reset</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
