import Header from "../components/header/header";
import Footer from "../components/footer";
import ChangePasswordContainer from "../containers/change-password-container/change-password-container";

export default function ChangePasswordPage() {
  return (
    <div className="h-screen flex flex-col z-50 bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
      {/* HEADER */}
      <Header />

      <div className="lg:max-w-7xl md:max-w-sm min-h-[90vh] flex items-center justify-center max-w-xs mx-auto w-full">
        <ChangePasswordContainer />
      </div>
      <Footer />
    </div>
  );
}
