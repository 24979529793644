import { FundingOpportunity } from "../core/types/types";
import { FiCheck, FiCheckCircle, FiChevronRight, FiX } from "react-icons/fi";
import { useCallback, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { toast_error } from "../core/hooks/toast_error";
import Header from "../components/header/header";
import Footer from "../components/footer";
import { BiShareAlt } from "react-icons/bi";
import { Button } from "../components/button";
import {
  ColorsEnum,
  VariantsEnum,
  SizesEnum,
  ModalSizeEnum,
  DirectionEnum,
} from "../core/enums/enums";
import nihLogo from "../assets/nih-logo.svg";
import {
  countShareOpportunityService,
  fetchPublicOpportunityByIdService,
  updateUserInterestedService,
} from "../core/services/shared.service";
import { isExpired } from "react-jwt";
import { ClipLoader } from "react-spinners";
import Modal from "../components/modal/modal-component";
import { InputField } from "../components/input-field/input-field";

export default function DetailsPage() {
  const [didCopy, setDidCopy] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedDate, setSelectedDate] = useState<string>("");
  const { opportunityId = "" } = useParams<{ opportunityId: string }>();
  const [opportunity, setOpportunity] = useState<FundingOpportunity>();
  const token = sessionStorage.getItem("access_token");
  const isLoggedIn = token ? !isExpired(token) : false;

  const fetchOpportunityData = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchPublicOpportunityByIdService(opportunityId);
      setOpportunity(res);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  }, [opportunityId]);

  const handleInterested = async (interested: boolean | null) => {
    if (!isLoggedIn) {
      toast.error("you must log in first");
      return;
    }

    try {
      await updateUserInterestedService({
        funding_id: opportunityId,
        interested: interested,
        reminder_date: interested && selectedDate ? selectedDate : "",
      });

      toast.success("Success");
      fetchOpportunityData();
      setIsModalOpen(false);
    } catch (error) {
      toast_error(error);
    }
  };

  const handleShare = async () => {
    try {
      await countShareOpportunityService(opportunityId);
    } catch (error) {
      toast_error(error);
    }
  };

  const copyLink = () => {
    setDidCopy(true);
    navigator.clipboard.writeText(
      `${process.env["REACT_APP_FRONTEND_BASE_URL"]}/opportunities/${opportunity?.id}`
    );
    setTimeout(() => {
      setDidCopy(false);
    }, 1000);
    if (isLoggedIn) {
      handleShare();
    }
  };

  useEffect(() => {
    if (opportunityId) {
      fetchOpportunityData();
    }
  }, [fetchOpportunityData, opportunityId]);

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm max-w-xs mx-auto w-full lg:mt-20 mb-10">
          <div className="flex items-center gap-3 mb-9">
            <Link
              to={"/opportunities"}
              className="text-[#64748B] text-2xl font-bold"
            >
              Opportunities
            </Link>
            <p className="text-[#64748B] text-2xl font-bold">/</p>
            <p className="text-[#0C6BAF] text-4xl font-bold">Details </p>
          </div>
        </div>
        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full">
          {loading ? (
            <div className="flex justify-center h-full">
              <ClipLoader
                color={"#0284c7"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <>
              <div className="lg:w-[312px] w-full h-full flex flex-col gap-6 flex-shrink-0">
                {opportunity?.categories &&
                  opportunity?.categories?.length > 0 && (
                    <div className="p-4 bg-white w-full rounded-[20px]">
                      <p className="text-[#272D31] font-bold text-[10px] mb-3">
                        Tags
                      </p>
                      <div className="flex flex-wrap gap-2 items-center w-full">
                        {opportunity?.categories?.map((e) => (
                          <p
                            key={e.id}
                            className="bg-[#F2F5F7] text-[#272D31] p-2 rounded-xl flex items-center justify-center w-fit text-xs font-bold"
                          >
                            {e.name}
                          </p>
                        ))}
                      </div>
                    </div>
                  )}
                <div className="p-4 bg-white w-full rounded-[20px]">
                  <p className="text-[#272D31] font-bold text-[10px] mb-3">
                    Date
                  </p>
                  <p className="text-xs text-[#AEB7BD]">
                    Published:{" "}
                    <span className="text-[#8C9AA3] font-bold text-xs">
                      {new Date(opportunity?.publish_date || "").toDateString()}
                    </span>
                  </p>
                  {opportunity?.due_date && (
                    <p className="text-xs text-[#AEB7BD]">
                      Due date:{" "}
                      <span className="text-[#8C9AA3] font-bold text-xs">
                        {new Date(opportunity?.due_date).toDateString()}
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div>
                <div className="bg-white lg:p-12 p-6 rounded-[20px] w-full mx-auto mb-6 relative">
                  {isLoggedIn &&
                    opportunity?.opportunity_interest?.interested !==
                      undefined && (
                      <div
                        className={`absolute flex items-center justify-center gap-1 w-fit -top-3 left-10 rounded-[20px] p-1 px-2 text-white text-xs font-bold ${
                          opportunity?.opportunity_interest?.interested
                            ? "bg-[#0BBB87]"
                            : "bg-[#FF5C5C]"
                        }`}
                      >
                        <span>
                          {opportunity?.opportunity_interest?.interested
                            ? ""
                            : "Not "}
                          Interested
                        </span>
                        {opportunity?.opportunity_interest?.interested ? (
                          <FiCheck />
                        ) : (
                          <FiX />
                        )}
                      </div>
                    )}
                  <div className="flex items-center justify-between w-full">
                    <div className="text-[#64748B] flex-shrink-0 text-xs w-fit flex gap-2 items-center">
                      <img src={nihLogo} alt="" className="h-5 w-auto" />
                      <span>U.S. NIH Platform</span>
                    </div>
                    <div className="flex gap-2 mb-7 h-9 items-center justify-end w-full">
                      {opportunity?.type !== "NORMAL" && (
                        <div className="bg-[#01487B] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#0C6BAF] text-[10px]">
                          {opportunity?.type}
                        </div>
                      )}
                      {opportunity?.tags?.map((e) => (
                        <p
                          key={`${e}`}
                          className="bg-[#BC5C25] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#E96215] text-[10px]"
                        >
                          {e}
                        </p>
                      ))}
                    </div>
                  </div>

                  <h1 className="text-2xl font-bold text-black">
                    {opportunity?.title}
                  </h1>

                  <div className="my-7 w-full flex justify-end items-center">
                    <Button
                      color={ColorsEnum.Muted}
                      variant={VariantsEnum.Muted}
                      size={SizesEnum.Small}
                      className="font-bold"
                      onClick={copyLink}
                    >
                      {didCopy ? (
                        "Copied"
                      ) : (
                        <div className="flex items-center gap-1 justify-center">
                          <span>Share</span>
                          <BiShareAlt />
                        </div>
                      )}
                    </Button>
                  </div>

                  <div className="w-full h-px bg-[#0E273A] bg-opacity-10" />

                  <p className="text-base text-black font-bold my-6">
                    {opportunity?.description}
                  </p>

                  {opportunity?.eligibility?.map((val, index) => (
                    <p
                      key={val.text}
                      className={`${
                        val.type === "header" ? "my-4 font-bold" : ""
                      } ${index === 1 && "text-blue-800 font-bold mt-4"}`}
                    >
                      {val.text}
                    </p>
                  ))}

                  <div className="flex items-start justify-end gap-3">
                    <p className="text-[#64748B] text-sm">
                      For more details you can
                    </p>
                    <a
                      href={opportunity?.link}
                      target="_blank"
                      rel="noreferrer"
                      className="text-[#065FE6] flex items-center gap-1 justify-center text-sm font-bold"
                    >
                      <span>Visit the Website</span>
                      <FiChevronRight />
                    </a>
                  </div>
                </div>

                {isLoggedIn && (
                  <div className="bg-white lg:p-12 p-6 rounded-[20px] w-full mx-auto mb-6">
                    {opportunity?.opportunity_interest?.interested && (
                      <div className="flex items-center justify-between w-full gap-4">
                        <p className="flex items-center gap-2 text-[#0BBB87] text-sm font-bold">
                          <FiCheckCircle />I am Interested in this Opportunity
                        </p>
                        <Button
                          color={ColorsEnum.Muted}
                          variant={VariantsEnum.Muted}
                          size={SizesEnum.Medium}
                          onClick={() => {
                            handleInterested(null);
                          }}
                          className="font-bold !rounded-3xl !text-red-500"
                        >
                          Delete
                        </Button>
                      </div>
                    )}{" "}
                    {opportunity?.opportunity_interest?.interested ===
                      undefined && (
                      <div className="flex items-center justify-end w-full gap-4">
                        <Button
                          color={ColorsEnum.Muted}
                          variant={VariantsEnum.Muted}
                          size={SizesEnum.Medium}
                          onClick={() => {
                            handleInterested(false);
                          }}
                          className="font-bold !rounded-3xl !text-red-500"
                        >
                          Not Interested
                        </Button>
                        <Button
                          color={ColorsEnum.Success}
                          variant={VariantsEnum.Filled}
                          size={SizesEnum.Medium}
                          onClick={() => {
                            setIsModalOpen(true);
                          }}
                          className="font-bold !rounded-3xl !bg-success hover:!bg-success-600"
                        >
                          Interested
                        </Button>
                      </div>
                    )}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>

      {/* FOOTER */}
      <Footer />

      {isModalOpen && (
        <Modal size={ModalSizeEnum.MEDIUM}>
          <div className="flex flex-col gap-5 w-full">
            <div>
              <h5 className="text-black text-2xl font-bold mb-2">Reminder !</h5>
              <p className="text-[#64748B] text-xs">
                Select a date to remind you about this opportunity.
              </p>
            </div>
            <InputField
              type="date"
              onChange={(e) => setSelectedDate(e.target.value)}
              aria-label="Date:"
              dir={DirectionEnum.LTR}
              value={selectedDate ?? ""}
              placeholder="DD/MM/YYYY"
              min={new Date().toISOString().split("T")[0]}
              max={
                opportunity?.due_date
                  ? new Date(opportunity?.due_date).toISOString().split("T")[0]
                  : ""
              }
            />
            <div className="w-full flex justify-between gap-4 items-center">
              <Button
                color={ColorsEnum.Muted}
                variant={VariantsEnum.Muted}
                size={SizesEnum.Medium}
                type="button"
                onClick={() => {
                  handleInterested(true);
                }}
                className="font-bold !rounded-3xl w-full"
              >
                Skip
              </Button>
              <Button
                color={ColorsEnum.Success}
                variant={VariantsEnum.Filled}
                size={SizesEnum.Medium}
                disabled={!selectedDate}
                type="button"
                onClick={() => {
                  handleInterested(true);
                }}
                className="font-bold !rounded-3xl w-full"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
