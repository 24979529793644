import { Navigate } from "react-router-dom";
import RefreshTokenUpdateWrapperContainer from "../refresh-token-update-wrapper-container/refresh-token-update-wrapper-container";
import { useCallback, useEffect } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import { fetchUserDataService } from "../../core/services/shared.service";
import useUserDataStore, {
  UserDataStoreType,
} from "../../core/stores/user.store";
import { isExpired } from "react-jwt";

interface AuthAccessWrapperContainerProps {
  children: JSX.Element;
}

const AuthAccessWrapperContainer = ({
  children,
}: AuthAccessWrapperContainerProps) => {
  const token = sessionStorage.getItem("access_token") as string;
  const {
    setUserData,
    refreshUserData,
    setRefreshUserData,
  }: UserDataStoreType = useUserDataStore();

  const fetchUserData = useCallback(async () => {
    try {
      const res = await fetchUserDataService();

      setUserData(res);
    } catch (error) {
      toast_error(error);
    } finally {
      setRefreshUserData(false);
    }
  }, [setRefreshUserData, setUserData]);

  useEffect(() => {
    if ((token && !isExpired(token)) || refreshUserData) {
      fetchUserData();
    }
  }, [fetchUserData, token, refreshUserData]);

  if (!token) {
    const currentPath = window.location.pathname;
    sessionStorage.setItem("redirect_after_login", currentPath);
    
    return <Navigate to="/login" />;
  }

  return (
    <RefreshTokenUpdateWrapperContainer>
      {children}
    </RefreshTokenUpdateWrapperContainer>
  );
};

export default AuthAccessWrapperContainer;
