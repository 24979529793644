import { ColorsEnum, SizesEnum, VariantsEnum } from "../core/enums/enums";
import "./dynamic-classes";

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  size?: SizesEnum;
  variant?: VariantsEnum;
  color?: ColorsEnum;
  icon?: JSX.Element;
}

export const Button: React.FC<ButtonProps> = ({
  color = ColorsEnum.Primary,
  size = SizesEnum.Medium,
  variant = VariantsEnum.Filled,
  icon = null,
  ...props
}) => {
  const getSizes = () => {
    switch (size) {
      case SizesEnum.Small:
        return "px-4 h-9 text-xs";
      case SizesEnum.Medium:
        return "px-6 h-10 text-sm";
      case SizesEnum.Large:
        return "px-8 h-12 text-base";
      default:
        return "px-6 h-10 text-sm";
    }
  };

  const getVariantWithColor = () => {
    switch (variant) {
      case VariantsEnum.Filled:
        return `text-white focus:ring-2 ${getButtonBackground()}`;
      case VariantsEnum.Outline:
        return `bg-transparent border border-${color} text-${color} hover:bg-${color} hover:text-white focus:bg-${color} focus:border-0 focus:text-white`;
      case VariantsEnum.Link:
        return `bg-transparent hover:text-primary-600 text-primary`;
      case VariantsEnum.Muted:
        return `text-muted hover:bg-opacity-20 bg-opacity-10 focus:ring-2 bg-muted`;
      case VariantsEnum.White:
        return `bg-transparent border border-white text-white hover:bg-white hover:text-primary focus:bg-white focus:border-0 focus:text-white`;
      default:
        return `bg-transparent hover:text-primary-600 text-primary`;
    }
  };

  const getButtonBackground = () => {
    switch (color) {
      case ColorsEnum.Primary:
        return "bg-primary focus:ring-bg-primary-600 hover:bg-primary-600";
      case ColorsEnum.Success:
        return "bg-success focus:ring-bg-success-600 hover:bg-success-600";
      case ColorsEnum.Danger:
        return "bg-danger focus:ring-bg-danger-600 hover:bg-danger-600";
      case ColorsEnum.Warning:
        return "bg-warning focus:ring-bg-warning-600 hover:bg-warning-600";
      case ColorsEnum.Muted:
        return "bg-muted focus:ring-bg-muted-600 hover:bg-muted-600";
      default:
        return "bg-primary focus:ring-bg-primary-600 hover:bg-primary-600";
    }
  };

  return (
    <button
      {...props}
      className={`inline-flex items-center rounded-md ${getSizes()} ${getVariantWithColor()} rounded-btn-custom justify-center disabled:cursor-not-allowed disabled:opacity-40 ${
        props.className ?? ""
      }`}
    >
      {icon ? (
        <>
          <span className="inline-block">{icon}</span>
          <span className="mr-2 inline-block">{props.children}</span>
        </>
      ) : (
        <span>{props.children}</span>
      )}
    </button>
  );
};
