import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import ListPage from "./screens/list";
import MyInterests from "./screens/my_interests";
import reportWebVitals from "./reportWebVitals";
import ToastMessage from "./toast-message";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Dashboard from "./screens/dashboard";
import ResetPasswordScreen from "./screens/reset_password";
import CreateOrEditOpportunity from "./components/dashboard/create_or_edit_opportunity";
import Landing from "./screens/landing";
import DetailsPage from "./screens/details-page";
import ContactPage from "./screens/contact";
import LoginPage from "./screens/login-page";
import SignUpPage from "./screens/signup-page";
import UserProfileWrapper from "./containers/user-profile-wrapper/user-profile-wrapper";
import SettingPage from "./screens/setting-page";
import ChangePasswordPage from "./screens/change-password-page";
import ChangeEmailPage from "./screens/change-email-page";
import OpportunitiesList from "./components/dashboard/opportunities_list";
import AdminsList from "./components/dashboard/admins_list";
import CategoriesList from "./components/dashboard/categories_list/categories_list";
import FeedbackList from "./components/dashboard/feedback_list";
import HistoryList from "./components/dashboard/history_list";
import MembersList from "./components/dashboard/members_list";
import AuthAccessWrapperContainer from "./containers/auth-access-wrapper-container/auth-access-wrapper-container";
import SaudiListPage from "./screens/saudi-list";
import ArchivedListPage from "./screens/archived-list";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

export default function App() {
  const withAuth = (Component: JSX.Element) => {
    return <AuthAccessWrapperContainer>{Component}</AuthAccessWrapperContainer>;
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Landing />} />
        <Route path="/opportunities" element={<ListPage />} />
        <Route path="/opportunities/archived" element={<ArchivedListPage />} />
        <Route path="/opportunities/saudi-nih" element={<SaudiListPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/signup" element={<SignUpPage />} />
        <Route path="/contact-us" element={<ContactPage />} />
        <Route
          path="/opportunities/:opportunityId"
          element={withAuth(<DetailsPage />)}
        />
        <Route path="/dashboard" element={withAuth(<Dashboard />)}>
          <Route
            path="/dashboard/opportunities"
            element={<OpportunitiesList />}
          />
          <Route path="/dashboard/feedbacks" element={<FeedbackList />} />
          <Route path="/dashboard/history" element={<HistoryList />} />
          <Route path="/dashboard/members" element={<MembersList />} />
          <Route path="/dashboard/admins" element={<AdminsList />} />
          <Route path="/dashboard/categories" element={<CategoriesList />} />
          <Route
            path="*"
            element={<Navigate to="/dashboard/opportunities" />}
          />
          <Route index element={<Navigate to="/dashboard/opportunities" />} />
        </Route>
        <Route
          path="/dashboard/opportunities/:opportunity_id/edit"
          element={withAuth(<CreateOrEditOpportunity />)}
        />
        <Route
          path="/dashboard/opportunities/create"
          element={withAuth(<CreateOrEditOpportunity />)}
        />
        <Route
          path="/forgot_password/:token"
          element={withAuth(<ResetPasswordScreen />)}
        />
        <Route
          path="/change-password"
          element={withAuth(<ChangePasswordPage />)}
        />
        <Route path="/change-email" element={withAuth(<ChangeEmailPage />)} />
        <Route path="/user" element={withAuth(<UserProfileWrapper />)}>
          <Route path="/user/my_interests" element={<MyInterests />} />
          <Route path="/user/settings" element={<SettingPage />} />
          <Route path="*" element={<Navigate to="/user/my_interests" />} />
          <Route index element={<Navigate to="/user/my_interests" />} />
        </Route>
        <Route path="*" element={<Navigate to={"/"} />} />
      </Routes>
    </BrowserRouter>
  );
}

root.render(
  <React.StrictMode>
    <App />
    <ToastMessage />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
