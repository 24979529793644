import Header from "../components/header/header";
import Footer from "../components/footer";
import { FiCrosshair } from "react-icons/fi";
import {
  PiChatTeardropDots,
  PiGlobeHemisphereEast,
  PiListMagnifyingGlass,
  PiLockOpen,
  PiUsersThree,
} from "react-icons/pi";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export default function Dashboard() {
  const navigate = useNavigate();
  const location = useLocation();
  const menuItems: { icon: JSX.Element; text: string; to: string }[] = [
    {
      icon: <FiCrosshair />,
      text: "Opportunities",
      to: "/dashboard/opportunities",
    },
    {
      icon: <PiChatTeardropDots />,
      text: "Feedbacks",
      to: "/dashboard/feedbacks",
    },
    {
      icon: <PiListMagnifyingGlass />,
      text: "History",
      to: "/dashboard/history",
    },
    { icon: <PiUsersThree />, text: "Members", to: "/dashboard/members" },
    { icon: <PiLockOpen />, text: "Admins", to: "/dashboard/admins" },
    {
      icon: <PiGlobeHemisphereEast />,
      text: "Categories",
      to: "/dashboard/categories",
    },
  ];

  return (
      <div className="h-screen">
        <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
          {/* HEADER */}
          <Header />

          <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
            <div className="lg:w-[312px] w-full h-full flex flex-col gap-6 flex-shrink-0">
              <div className="p-4 bg-white w-full rounded-[20px]">
                <div className="flex flex-col gap-1">
                  {menuItems.map((item, index) => (
                    <button
                      key={`to-${index}`}
                      className={`gap-3 text-sm transition-all duration-300 cursor-pointer text-[#64748B] font items-center flex hover:font-bold hover:text-[#0C6BAF] p-4 rounded-xl bg-transparent hover:bg-[#F2F5F7] disabled:bg-[#F2F5F7] disabled:font-bold disabled:text-[#0C6BAF] ${
                        item.to === location.pathname
                          ? "font-bold text-[#0C6BAF]"
                          : ""
                      }`}
                      onClick={() => navigate(item.to)}
                      disabled={item.to === location.pathname}
                    >
                      {item.icon}
                      <span>{item.text}</span>
                    </button>
                  ))}
                </div>
              </div>
            </div>
            <div className="w-full">
              <Outlet />
            </div>
          </div>
        </div>

        {/* FOOTER */}
        <Footer />
      </div>
  );
}
