import Header from "../../components/header/header";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { PiBookmarkSimple, PiChatTeardropDots } from "react-icons/pi";

export default function UserProfileWrapper() {
  const navigate = useNavigate();
  const location = useLocation();

  return (
      <div className="h-screen flex flex-col z-50 bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
          <div className="lg:w-[312px] w-full lg:h-full flex flex-col gap-6 flex-shrink-0">
            {/* MENU */}
            <div className="p-4 bg-white w-full rounded-[20px]">
              <div className="flex flex-col gap-1">
                <button
                  className="gap-3 text-sm transition-all duration-300 cursor-pointer text-[#64748B] font items-center flex hover:font-bold hover:text-[#0C6BAF] p-4 rounded-xl bg-transparent hover:bg-[#F2F5F7] disabled:bg-[#F2F5F7] disabled:font-bold disabled:text-[#0C6BAF]"
                  onClick={() => navigate("/user/my_interests")}
                  disabled={location.pathname === "/user/my_interests"}
                >
                  <PiBookmarkSimple />
                  <span>My Interest</span>
                </button>
                <button
                  className="gap-3 text-sm transition-all duration-300 cursor-pointer text-[#64748B] font items-center flex hover:font-bold hover:text-[#0C6BAF] p-4 rounded-xl bg-transparent hover:bg-[#F2F5F7] disabled:bg-[#F2F5F7] disabled:font-bold disabled:text-[#0C6BAF]"
                  onClick={() => navigate("/user/settings")}
                  disabled={location.pathname === "/user/settings"}
                >
                  <PiChatTeardropDots />
                  <span>Settings</span>
                </button>
              </div>
            </div>
          </div>
          <div className="w-full">
            <Outlet />
          </div>
        </div>
      </div>
  );
}
