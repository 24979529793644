import { toast_error } from "../../core/hooks/toast_error";
import { Link, useNavigate } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { validationSchema } from "./signup-container.validation";
import { MobileField } from "../../components/mobile-field/mobile-field";
import { countries } from "../../core/constants/countries";
import { Select } from "../../components/select/select";
import { educations } from "../../core/constants/education";
import { RadioButton } from "../../components/radio-button/radio-button";
import { signupService } from "../../core/services/shared.service";
import { useState } from "react";
import { PiEye, PiEyeClosed } from "react-icons/pi";

export default function SignUpContainer() {
  const navigate = useNavigate();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean[]>([
    false,
    false,
  ]);

  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      mobile: "",
      country_code: "+966",
      nationality: "",
      education: "",
      institution: "",
      email: "",
      password: "",
      confirm_password: "",
    },
    onSubmit: () => {
      handleSubmit();
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      await signupService(formik.values);
      toast.success("Success");
      navigate("/login");
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <Link
          to="/"
          className="flex items-center gap-2 text-[#0C6BAF] font-bold text-sm"
        >
          <FiChevronLeft size={20} />
          <span>Back</span>
        </Link>
        <p className="text-black font-bold text-4xl">Register</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 items-start w-full">
            <InputField
              placeholder="Name"
              aria-label="First Name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir={DirectionEnum.LTR}
              value={formik.values.first_name.trimStart() ?? ""}
              isValid={formik.touched.first_name && !formik.errors.first_name}
              error={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ""
              }
            />
            <InputField
              placeholder="Name"
              aria-label="Last Name"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir={DirectionEnum.LTR}
              value={formik.values.last_name.trimStart() ?? ""}
              isValid={formik.touched.last_name && !formik.errors.last_name}
              error={
                formik.touched.last_name && formik.errors.last_name
                  ? formik.errors.last_name
                  : ""
              }
            />
          </div>
          <MobileField
            aria-label="Mobile number"
            placeholder="XX XXXX XXX"
            name="mobile"
            value={formik.values.mobile}
            dir={DirectionEnum.LTR}
            onChange={formik.handleChange}
            handlePhoneCodeChange={(code) => {
              formik.setFieldValue("country_code", code);
            }}
            onBlur={formik.handleBlur}
            isValid={formik.touched.mobile && !formik.errors.mobile}
            error={
              formik.touched.mobile && formik.errors.mobile
                ? formik.errors.mobile
                : ""
            }
          />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 items-start w-full">
            <Select
              height={SizesEnum.Large}
              listToDisplay={countries.map((e) => e.name)}
              dir={DirectionEnum.LTR}
              isValid={formik.touched.nationality && !formik.errors.nationality}
              name="nationality"
              onChange={formik.handleChange}
              value={formik.values.nationality}
              aria-label="Nationality"
              error={
                formik.touched.nationality && formik.errors.nationality
                  ? formik.errors.nationality
                  : ""
              }
            />
            <Select
              height={SizesEnum.Large}
              listToDisplay={educations.map((e) => e.name)}
              dir={DirectionEnum.LTR}
              isValid={formik.touched.education && !formik.errors.education}
              name="education"
              onChange={formik.handleChange}
              value={formik.values.education}
              aria-label="Education"
              error={
                formik.touched.education && formik.errors.education
                  ? formik.errors.education
                  : ""
              }
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-bold text-slate-500 dark:text-slate-300">
              Institution
            </label>
            <div className="inline-flex gap-11 my-1">
              {["KFSHRC", "Other"].map((item: string) => {
                return (
                  <RadioButton
                    key={item}
                    name="institution"
                    value={item}
                    checked={formik.values.institution === item}
                    aria-label={item}
                    onChange={formik.handleChange}
                  />
                );
              })}
            </div>
            {formik.touched.institution && formik.errors.institution ? (
              <div className="text-red-500 text-xs">
                {formik.errors.institution}
              </div>
            ) : (
              ""
            )}
          </div>
          <InputField
            placeholder="email@email.com"
            aria-label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            value={formik.values.email.trimStart() ?? ""}
            isValid={formik.touched.email && !formik.errors.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Password"
            type={isPasswordVisible[0] ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[0] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([false, isPasswordVisible[1]])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([true, isPasswordVisible[1]])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.password.trimStart() ?? ""}
            isValid={formik.touched.password && !formik.errors.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Confirm Password"
            type={isPasswordVisible[1] ? "text" : "password"}
            name="confirm_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[1] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([isPasswordVisible[0], false])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([isPasswordVisible[0], true])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.confirm_password.trimStart() ?? ""}
            isValid={
              formik.touched.confirm_password && !formik.errors.confirm_password
            }
            error={
              formik.touched.confirm_password && formik.errors.confirm_password
                ? formik.errors.confirm_password
                : ""
            }
          />

          {/* ACTIONS */}
          <div className="flex  justify-end w-full">
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Sign Up</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 font-bold text-sm">
          <span className="text-[#64748B]">Already have an account?</span>{" "}
          <Link to="/login" className="text-[#0C6BAF]">
            Log in
          </Link>
        </div>
      </div>
    </form>
  );
}
