import { toast_error } from "../../core/hooks/toast_error";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronRight } from "react-icons/fi";
import { validationSchema } from "./edit-user-container.validation";
import { MobileField } from "../../components/mobile-field/mobile-field";
import { countries } from "../../core/constants/countries";
import { Select } from "../../components/select/select";
import { educations } from "../../core/constants/education";
import { RadioButton } from "../../components/radio-button/radio-button";
import { PiPencilSimpleLine } from "react-icons/pi";
import { useNavigate } from "react-router-dom";
import { updateUserDataService } from "../../core/services/shared.service";
import useUserDataStore, {
  UserDataStoreType,
} from "../../core/stores/user.store";

export default function EditUserContainer() {
  const navigate = useNavigate();
  const { userData, setRefreshUserData }: UserDataStoreType =
    useUserDataStore();

  const formik = useFormik({
    initialValues: {
      first_name: userData?.first_name ?? "",
      last_name: userData?.last_name ?? "",
      mobile: userData?.mobile ?? "",
      country_code: userData?.country_code ?? "+966",
      education: userData?.education ?? "",
      institution: userData?.institution ?? "",
      nationality: userData?.nationality ?? "",
    },
    onSubmit: () => {
      handleSubmit();
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      await updateUserDataService(formik.values);
      toast.success("Updated Successfully");
      setRefreshUserData(true);
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <div className="w-full h-full flex flex-col gap-6">
      <p className="text-black font-bold text-4xl">Settings</p>
      <form onSubmit={formik.handleSubmit} className="w-full">
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 items-start w-full">
            <InputField
              placeholder="Name"
              aria-label="First Name"
              name="first_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir={DirectionEnum.LTR}
              value={formik.values.first_name.trimStart() ?? ""}
              isValid={formik.touched.first_name && !formik.errors.first_name}
              error={
                formik.touched.first_name && formik.errors.first_name
                  ? formik.errors.first_name
                  : ""
              }
            />
            <InputField
              placeholder="Name"
              aria-label="Last Name"
              name="last_name"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              dir={DirectionEnum.LTR}
              value={formik.values.last_name.trimStart() ?? ""}
              isValid={formik.touched.last_name && !formik.errors.last_name}
              error={
                formik.touched.last_name && formik.errors.last_name
                  ? formik.errors.last_name
                  : ""
              }
            />
          </div>
          <MobileField
            aria-label="Mobile number"
            placeholder="XX XXXX XXX"
            name="mobile"
            value={formik.values.mobile}
            dir={DirectionEnum.LTR}
            onChange={formik.handleChange}
            handlePhoneCodeChange={(code) => {
              formik.setFieldValue("country_code", code);
            }}
            onBlur={formik.handleBlur}
            isValid={formik.touched.mobile && !formik.errors.mobile}
            error={
              formik.touched.mobile && formik.errors.mobile
                ? formik.errors.mobile
                : ""
            }
          />
          <div className="grid lg:grid-cols-2 grid-cols-1 gap-3 items-start w-full">
            <Select
              height={SizesEnum.Large}
              listToDisplay={countries.map((e) => e.name)}
              dir={DirectionEnum.LTR}
              isValid={formik.touched.nationality && !formik.errors.nationality}
              name="nationality"
              onChange={formik.handleChange}
              value={formik.values.nationality}
              aria-label="Nationality"
              error={
                formik.touched.nationality && formik.errors.nationality
                  ? formik.errors.nationality
                  : ""
              }
            />
            <Select
              height={SizesEnum.Large}
              listToDisplay={educations.map((e) => e.name)}
              dir={DirectionEnum.LTR}
              isValid={formik.touched.education && !formik.errors.education}
              name="education"
              onChange={formik.handleChange}
              value={formik.values.education}
              aria-label="Education"
              error={
                formik.touched.education && formik.errors.education
                  ? formik.errors.education
                  : ""
              }
            />
          </div>

          <div>
            <label className="mb-2 block text-sm font-bold text-slate-500 dark:text-slate-300">
              Institution
            </label>
            <div className="inline-flex gap-11 my-1">
              {["KFSHRC", "Other"].map((item: string) => {
                return (
                  <RadioButton
                    key={item}
                    name="institution"
                    value={item}
                    checked={formik.values.institution === item}
                    aria-label={item}
                    onChange={formik.handleChange}
                  />
                );
              })}
            </div>
            {formik.touched.institution && formik.errors.institution ? (
              <div className="text-red-500 text-xs">
                {formik.errors.institution}
              </div>
            ) : (
              ""
            )}
          </div>

          {/* ACTIONS */}
          <div className="flex  justify-end w-full">
            <Button
              color={ColorsEnum.Success}
              variant={VariantsEnum.Filled}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              disabled={!formik.dirty}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Save</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
      </form>
      <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
        <div className="flex items-center gap-4">
          <InputField
            placeholder="email@email.com"
            aria-label="Email"
            name="email"
            type="email"
            value={userData?.email ?? ""}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            readOnly
            disabled
          />

          <Button
            color={ColorsEnum.Primary}
            variant={VariantsEnum.None}
            size={SizesEnum.Medium}
            type="button"
            className="font-bold"
            onClick={() => navigate("/change-email")}
          >
            <div className="flex items-center gap-1 justify-center">
              <span>Edit</span>
              <PiPencilSimpleLine />
            </div>
          </Button>
        </div>
        <div>
          <Button
            color={ColorsEnum.Muted}
            variant={VariantsEnum.Muted}
            size={SizesEnum.Medium}
            type="button"
            className="font-bold !rounded-3xl"
            onClick={() => navigate("/change-password")}
          >
            <div className="flex items-center gap-1 justify-center">
              <span>Change Password</span>
              <FiChevronRight />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
