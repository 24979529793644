import { useState } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import { Link, useNavigate } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { validationSchema } from "./login-container.validation";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { CheckboxButton } from "../../components/checkbox-button/checkbox-button";
import {
  forgetPasswordService,
  loginService,
} from "../../core/services/shared.service";
import { PiEye, PiEyeClosed } from "react-icons/pi";

export default function LoginContainer() {
  const [isRemembered, setIsRemembered] = useState<boolean>(
    localStorage.getItem("email") ? true : false
  );
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
  const [forgetPasswordView, setForgetPasswordView] = useState<boolean>(false);
  const navigate = useNavigate();

  const formik = useFormik({
    initialValues: {
      email: localStorage.getItem("email") ?? "",
      password: "",
    },
    onSubmit: () => {
      forgetPasswordView ? handleResetPassword() : handleSubmit();
    },
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleResetPassword = async () => {
    try {
      await forgetPasswordService({ email: formik.values.email });
      toast("Success, check your email", {
        style: { backgroundColor: "#38a169", color: "white" },
      });
    } catch (error) {
      toast_error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      const res = await loginService(formik.values);
      sessionStorage.setItem("access_token", res.access_token);
      const redirectAfterLogin = sessionStorage.getItem("redirect_after_login");
      if (redirectAfterLogin) {
        navigate(redirectAfterLogin);
        sessionStorage.removeItem("redirect_after_login");
      } else {
        navigate("/");
      }
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <Link
          to="/"
          className="flex items-center gap-2 text-[#0C6BAF] font-bold text-sm"
        >
          <FiChevronLeft size={20} />
          <span>Back</span>
        </Link>
        <p className="text-black font-bold text-4xl">Log in</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <InputField
            placeholder="email@email.com"
            aria-label="Email"
            name="email"
            type="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            value={formik.values.email.trimStart() ?? ""}
            isValid={formik.touched.email && !formik.errors.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Password"
            type={isPasswordVisible ? "text" : "password"}
            name="password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            endIcon={
              isPasswordVisible ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() => setIsPasswordVisible(false)}
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() => setIsPasswordVisible(true)}
                />
              )
            }
            value={formik.values.password.trimStart() ?? ""}
            isValid={formik.touched.password && !formik.errors.password}
            error={
              formik.touched.password && formik.errors.password
                ? formik.errors.password
                : ""
            }
          />
          <div className="flex items-center justify-between w-full gap-3">
            <CheckboxButton
              color={ColorsEnum.Primary}
              aria-label="Remember me"
              checked={isRemembered}
              button_size={SizesEnum.Medium}
              onChange={() => {
                if (!isRemembered) {
                  localStorage.setItem("email", formik.values.email);
                } else {
                  localStorage.removeItem("email");
                }

                setIsRemembered(!isRemembered);
              }}
            />

            <Button
              color={ColorsEnum.Primary}
              variant={VariantsEnum.None}
              type="button"
              onClick={() => {
                setForgetPasswordView(true);
                formik.submitForm();
              }}
              className="font-bold"
            >
              Forgot Your Password?
            </Button>
          </div>
          {/* ACTIONS */}
          <div className="flex justify-end w-full">
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Log in</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
        <div className="flex items-center justify-center gap-2 font-bold text-sm">
          <span className="text-[#64748B]">Don’t have an account yet?</span>{" "}
          <Link to="/signup" className="text-[#0C6BAF]">
            Create One
          </Link>
        </div>
      </div>
    </form>
  );
}
