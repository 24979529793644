import { useState } from "react";
import { toast_error } from "../core/hooks/toast_error";
import Header from "../components/header/header";
import Footer from "../components/footer";
import { ColorsEnum, SizesEnum, VariantsEnum } from "../core/enums/enums";
import { toast } from "react-toastify";
import { MdAlternateEmail } from "react-icons/md";
import { HiOutlineDeviceMobile } from "react-icons/hi";
import { FiChevronRight } from "react-icons/fi";
import { Button } from "../components/button";
import { submitFeedbackService } from "../core/services/shared.service";

function ContactPage() {
  const [feedbackData, setFeedbackData] = useState("");

  const handleSubmit = async () => {
    try {
      await submitFeedbackService({ text: feedbackData });
      toast.success("Success");
      setFeedbackData("");
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full lg:mt-20">
          <div className="lg:w-[312px] w-full h-full flex flex-col gap-6 flex-shrink-0 lg:order-first order-last">
            <p className="text-black font-bold text-4xl lg:block hidden">
              Contact Us
            </p>
            <div className="p-4 bg-white w-full rounded-[20px]">
              <p className="text-[#272D31] font-bold text-[10px] mb-3 lg:text-start text-center">
                Technical Support
              </p>
              <div className="flex flex-col gap-1">
                <div className="flex items-center p-3 bg-[#F2F5F7] lg:w-full w-fit mx-auto rounded-xl gap-2">
                  <MdAlternateEmail className="text-[#0BBB87]" />
                  <a
                    href="mailto:info@TornadoGI.com"
                    className="text-[#0C6BAF] font-bold text-xs"
                  >
                    info@TornadoGI.com
                  </a>
                </div>
              </div>
            </div>
            <div className="p-4 bg-white w-full rounded-[20px]">
              <p className="text-[#272D31] font-bold text-[10px] mb-3 lg:text-start text-center">
                For Employee of KFSHRC
              </p>
              <div className="flex flex-col gap-1">
                <div className="flex items-center p-3 bg-[#F2F5F7] lg:w-full w-fit mx-auto rounded-xl gap-2">
                  <HiOutlineDeviceMobile className="text-[#0BBB87]" />
                  <a href="tel:" className="text-[#0C6BAF] font-bold text-xs">
                    -------------
                  </a>
                </div>
                <div className="flex items-center p-3 bg-[#F2F5F7] lg:w-full w-fit mx-auto rounded-xl gap-2">
                  <MdAlternateEmail className="text-[#0BBB87]" />
                  <a
                    href="mailto:"
                    className="text-[#0C6BAF] font-bold text-xs"
                  >
                    -------------
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="w-full lg:pt-16 lg:order-last order-first">
            <p className="text-black font-bold text-4xl mb-6 lg:hidden block">
              Contact Us
            </p>
            <div className="p-8 bg-white w-full rounded-[20px] flex-col flex gap-6">
              <p className="text-[#272D31] font-bold text-base">
                Share With Us Your Feedback!
              </p>
              <textarea
                onChange={(e) => {
                  setFeedbackData(e.target.value);
                }}
                value={feedbackData}
                className="rounded-lg w-full p-3 bg-[#EDF0F2] placeholder:text-[#64748B]"
                rows={10}
                placeholder="Your feedback here..."
              />
              <div className="flex items-center justify-end w-full">
                <Button
                  color={ColorsEnum.Success}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Medium}
                  onClick={handleSubmit}
                  disabled={feedbackData.trim().length < 3}
                  className="font-bold !rounded-3xl !bg-success hover:!bg-success-600"
                >
                  <div className="flex items-center text-white gap-1 justify-center">
                    <span>Submit</span>
                    <FiChevronRight />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}

export default ContactPage;
