import { FiChevronDown } from "react-icons/fi";
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { DirectionEnum } from "../../core/enums/enums";

type SelectFormat = {
  label: string;
  value: string;
};

export interface SelectProps
  extends React.InputHTMLAttributes<HTMLSelectElement> {
  isValid?: boolean;
  hint?: string;
  error?: string;
  listToDisplay: string[] | SelectFormat[];
  selectText?: string;
  startIcon?: JSX.Element;
}
export function Select({
  hint,
  error,
  isValid,
  listToDisplay,
  startIcon,
  selectText = "select ...",
  ...props
}: SelectProps): JSX.Element {
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "outline-none border-2 border-emerald-500 text-emerald-500 !bg-emerald-500 !bg-opacity-5";
      case false:
        return "outline-none border-2 border-red-500 text-red-500 !bg-red-500 !bg-opacity-5";
      default:
        return "border-2 border-transparent focus:border-2 focus:border-blue-400 focus:bg-white focus:outline-none border-0";
    }
  };

  const getValidationIcon = () => {
    switch (isValid) {
      case true:
        return <HiOutlineCheckCircle className="text-base text-emerald-500" />;
      case false:
        return (
          <HiOutlineExclamationCircle className="text-base text-red-500" />
        );
      default:
        return;
    }
  };

  const getErrorClass = () => {
    switch (isValid) {
      case true:
        return "text-emerald-500";
      case false:
        return "text-red-500";
      default:
        return "text-slate-500 dark:text-slate-300";
    }
  };

  return (
    <div className={`mb-2 w-full ${props.disabled ? "opacity-40" : ""}`}>
      {props["aria-label"] && (
        <label className={`mb-2 block text-sm font-bold ${getErrorClass()}`}>
          {props["aria-label"]}
        </label>
      )}

      <div className="relative py-2">
        {Boolean(startIcon) && (
          <div
            className={`absolute top-6 ${
              props.dir === DirectionEnum.LTR ? "left-4" : "right-4"
            }`}
          >
            {startIcon}
          </div>
        )}
        <select
          {...props}
          className={`disabled:bg-muted disabled:border-muted inline-block h-fit w-full min-w-full !appearance-none rounded-xl bg-gray-500/5 !bg-none py-2 text-base leading-7 text-slate-500 ring-0 ring-transparent focus:border-blue-400 focus:ring-0 focus:ring-transparent disabled:border-opacity-40 disabled:bg-opacity-20 dark:bg-slate-800 dark:text-white
          ${getValidationClass()} ${props.className ?? ""} ${
            startIcon
              ? "px-12"
              : props.dir === DirectionEnum.RTL
              ? "pl-12 pr-4"
              : "pl-4 pr-12"
          }`}
          title={props.title}
        >
          <option
            value=""
            hidden
            className="text-slate-500 opacity-40 dark:text-white"
          >
            {selectText}
          </option>

          {listToDisplay.map((item: string | SelectFormat) => {
            if (typeof item === "string") {
              return (
                <option value={item} key={item}>
                  {item}
                </option>
              );
            }
            return (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            );
          })}
        </select>
        <div
          className={`absolute top-6 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-4" : "right-4"
          }`}
        >
          <FiChevronDown className={`${getErrorClass()} text-base`} />
          {getValidationIcon()}
        </div>
        <span
          className={`absolute inset-y-0 flex items-center pl-2 ${
            props.dir === DirectionEnum.RTL ? "left-0" : "right-0"
          }`}
        ></span>
      </div>
      {hint && (
        <p className="text-xs text-slate-400 opacity-60 dark:text-slate-300">
          {hint}
        </p>
      )}
      {error && <p className={`text-xs ${getErrorClass()}`}>{error}</p>}
    </div>
  );
}
