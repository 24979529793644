import { useState, useEffect } from "react";
import ClipLoader from "react-spinners/ClipLoader";
import { MyInterestsType } from "../core/types/types";
import { toast_error } from "../core/hooks/toast_error";
import Card from "../components/card";
import { useNavigate } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";
import { fetchUserInterestsService } from "../core/services/shared.service";

export default function MyInterests() {
  const [myInterests, setMyInterests] = useState<MyInterestsType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const fetchData = async () => {
    setLoading(true);
    try {
      const res = await fetchUserInterestsService({ interested: true });
      setMyInterests(res);
    } catch (error) {
      setMyInterests([]);
      toast_error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {loading ? (
        <div className="flex justify-center h-full">
          <ClipLoader
            color={"#0284c7"}
            loading={loading}
            aria-label="Loading Spinner"
            data-testid="loader"
          />
        </div>
      ) : (
        <div className="mx-auto mb-auto">
          <div className="flex flex-col gap-6 mb-6">
            {myInterests?.map((interest) => (
              <Card
                data={interest.funding}
                interested={interest.interested}
                setSelectedId={() => {
                  unstable_batchedUpdates(() => {
                    navigate(`/opportunities/${interest.funding.id}`);
                  });
                }}
              />
            ))}
          </div>
        </div>
      )}
    </>
  );
}
