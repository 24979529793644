/**
 * @param data
 * @description for now, this function will remove any empty string values only.
 */
export function removeFalsyValues<T>(data: T): T {
  Object.keys(data as any).forEach((key) => {
    const k = key as keyof T;
    if (typeof data[k] === "string" || typeof data[k] === "object") {
      if (!data[k] || Object(data[k]).length === 0) {
        delete data[k];
      }
    }
  });
  return data;
}

/**
 * @param name key name
 * @param array selected values
 * @description convert array query params to string repeat with key name
 * @returns string with all values for the same key
 */
export const convertArrayParamToString = (name: string, array: string[]) => {
  let params = "";
  if (array.length > 0) {
    array.forEach((item) => {
      // handle empty value adding
      if (item) {
        params = params + `&${name}=${item}`;
      }
    });
  }

  return params;
};
