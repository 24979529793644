import { useCallback, useEffect, useState } from "react";

import {
  FiChevronLeft,
  FiChevronRight,
  FiMoreHorizontal,
} from "react-icons/fi";
import { ColorsEnum, SizesEnum, VariantsEnum } from "../../core/enums/enums";
import { Button } from "../button";

type SharedPaginationButtonsProps = {
  pagesCount: number;
  currentPage: number;
  onPageClick: (page: number) => void;
  color?: ColorsEnum;
};

export function SharedPaginationButtons({
  pagesCount,
  currentPage,
  onPageClick,
  color = ColorsEnum.Primary,
}: SharedPaginationButtonsProps) {
  const [paginationButtons, setPaginationButtons] = useState<number[]>([]); // for shown pages number
  const indexArray = Array(pagesCount)
    .fill("")
    .map((_, index) => index + 1); // to do the logic with it

  /**
   * @description this function will handle pagination cases to show only the needed pages
   * and replace the unneeded ones with dots (it will add -1 in the array for that) if pagesCount more than 6
   *
   * @example 1st case: [1st, 2nd, 3rd, ..., 3rd last, 2nd last, last] | 2nd case: [1st, ..., before current, current, after current, ..., last]
   *
   * @returns the shown pages array if pagesCount more than 6 or all pages
   */
  const hidePageNumber = useCallback(() => {
    if (pagesCount > 6) {
      if (currentPage >= 3 && currentPage <= pagesCount - 2) {
        const firstElement = indexArray.slice(0, 1);
        const lastElement = indexArray.slice(indexArray.length - 1);
        const middleElements = [currentPage - 1, currentPage, currentPage + 1];

        setPaginationButtons([
          ...firstElement,
          -1,
          ...middleElements,
          -1,
          ...lastElement,
        ]);
      } else if (currentPage <= 2 || currentPage > pagesCount - 2) {
        const lastThreeElement = indexArray.slice(
          indexArray.length - 3,
          indexArray.length
        );
        const firstThreeElement = indexArray.slice(0, 3);

        setPaginationButtons([...firstThreeElement, -1, ...lastThreeElement]);
      }
    } else {
      setPaginationButtons(indexArray);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pagesCount]);

  const getArrowsColor = () => {
    switch (color) {
      case ColorsEnum.Primary:
        return "text-primary";
      case ColorsEnum.Accent:
        return "text-accent";
      case ColorsEnum.Secondary:
        return "text-secondary";
      case ColorsEnum.Danger:
        return "text-danger";
      case ColorsEnum.Success:
        return "text-success";
      case ColorsEnum.Warning:
        return "text-warning";
      default:
        return "text-primary";
    }
  };

  useEffect(() => {
    hidePageNumber();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, pagesCount]);

  return (
    <ul className="flex items-center justify-center gap-0 sm:justify-between">
      <li>
        <button
          type="button"
          name="next page"
          title="next page"
          className={`${getArrowsColor()} ml-5 hidden disabled:cursor-not-allowed disabled:text-gray-400 sm:block`}
          disabled={currentPage === 1}
          onClick={() => onPageClick(currentPage - 1)}
        >
          <FiChevronLeft />
        </button>
      </li>
      {paginationButtons.map((item, index) => {
        if (item === currentPage) {
          return (
            <li key={index}>
              <Button
                color={color}
                size={SizesEnum.Small}
                variant={VariantsEnum.Filled}
                className="mx-1 font-bold"
              >
                {item}
              </Button>
            </li>
          );
        }
        if (item === -1) {
          return (
            <li key={index}>
              <Button
                color={ColorsEnum.Muted}
                variant={VariantsEnum.Muted}
                size={SizesEnum.Small}
                disabled
                className="mx-1"
              >
                <FiMoreHorizontal />
              </Button>
            </li>
          );
        }
        return (
          <li key={index}>
            <Button
              onClick={() => onPageClick(item as number)}
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Small}
              className="mx-1"
            >
              {item}
            </Button>
          </li>
        );
      })}
      <li>
        <button
          type="button"
          name="prev page"
          title="prev page"
          className={`${getArrowsColor()} mr-5 hidden disabled:cursor-not-allowed disabled:text-gray-400 sm:block`}
          disabled={currentPage === pagesCount}
          onClick={() => onPageClick(currentPage + 1)}
        >
          <FiChevronRight />
        </button>
      </li>
    </ul>
  );
}

export default SharedPaginationButtons;
