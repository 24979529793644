import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object({
    new_password: Yup.string().required("Required"),
    confirm_new_password: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("new_password")], "passwords are not the same"),
  });
};
