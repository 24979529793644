import { Feedback } from "../../core/types/types";
import { useState, useEffect } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import SharedPagination from "../pagination/pagination";
import { fetchFeedbackListService } from "../../core/services/shared.service";

export default function FeedbackList() {
  const [feedback, setFeedback] = useState<Feedback[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  useEffect(() => {
    fetchFeedback();
    // eslint-disable-next-line
  }, [currentPage]);

  const fetchFeedback = async () => {
    setLoading(true);
    try {
      const res: any = await fetchFeedbackListService({
        limit: limit,
        offset: limit * (currentPage - 1),
      });

      setFeedback(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  };

  if (loading)
    return (
      <div className="w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  if (feedback?.length === 0)
    return <div className="w-full text-center">No Data</div>;

  return (
    <div className="w-full">
      <div className="flex gap-4 flex-col mb-6">
        <p className="text-black font-bold lg:text-4xl">Feedbacks</p>
        <div className="text-[#64748B] font-bold text-sm">
          Result : {totalCount}
        </div>
        {feedback?.map((e) => {
          return (
            <div
              className="rounded-2xl group cursor-pointer flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
              key={e.text}
            >
              <p className="text-sm min-h-[72px] text-black transition-all duration-300 group-hover:text-[#0C6BAF]">
                {e.text}
              </p>
              <p className="text-end text-[#8C9AA3] text-xs font-bold">
                {new Date(e.created).toDateString()}
              </p>
            </div>
          );
        })}
        <div className="w-full mx-auto mb-4">
          <SharedPagination
            limit={limit}
            totalListCount={totalCount}
            onPageClick={(page: number) => {
              setCurrentPage(page);
            }}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
