import { History } from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import SharedPagination from "../pagination/pagination";
import { fetchHistoryListService } from "../../core/services/shared.service";

export default function HistoryList() {
  const [history, setHistory] = useState<History[]>([]);
  const [loading, setLoading] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchHistory = useCallback(async () => {
    setLoading(true);
    try {
      const res = await fetchHistoryListService({
        limit: limit,
        offset: limit * (currentPage - 1),
      });
      setHistory(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      setHistory([]);
      toast_error(error);
    }
    setLoading(false);
  }, [currentPage]);

  useEffect(() => {
    fetchHistory();
  }, [fetchHistory]);

  if (loading)
    return (
      <div className="w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  if (history.length === 0)
    return <div className="w-full text-center">No Data</div>;

  return (
    <div className="w-full">
      <div className="flex gap-4 flex-col mb-6">
        <p className="text-black font-bold text-4xl">History</p>
        <div className="text-[#64748B] font-bold text-sm">
          Result : {totalCount}
        </div>

        {history.map((e) => {
          var entity_title = "";

          if(e.entity) {

            if (e.entity?.title !== undefined) {
              entity_title = e.entity?.title;
            } else {
              entity_title = `${e.entity?.email}`;
            }
          } else {
            entity_title = e.action;
          }

          return (
            <div
              className="rounded-2xl group cursor-pointer flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
              key={e.entity}
            >
              <div className="grid items-center grid-cols-7 gap-2 w-full">
                <p className="text-sm text-[#272D31] group-hover:text-[#0C6BAF]">{`${e.admin.first_name} ${e.admin.last_name}`}</p>
                <p className="col-span-2 text-sm text-[#272D31] group-hover:text-[#0C6BAF]">
                  {e.admin.email}
                </p>
                <div className="text-sm text-center text-[#E61010] px-2 py-1 w-fit bg-[#E61010] bg-opacity-10 font-bold rounded-2xl">
                  {e.action}
                </div>
                <p className="col-span-3 text-sm text-[#272D31] group-hover:text-[#0C6BAF]">
                  {entity_title}
                </p>
              </div>
              <p className="text-end text-[#8C9AA3] text-xs font-bold">
                {new Date(e.created).toDateString()}
              </p>
            </div>
          );
        })}

        <div className="w-full mx-auto mb-4">
          <SharedPagination
            limit={limit}
            totalListCount={totalCount}
            onPageClick={(page: number) => {
              setCurrentPage(page);
            }}
            currentPage={currentPage}
          />
        </div>
      </div>
    </div>
  );
}
