import { useNavigate } from "react-router-dom";
import DropDownMenuButtonItem from "../dropdown/dropdown-menu-button-item";
import { PiBookmarkSimple, PiChartDonut, PiSignOut } from "react-icons/pi";
import { DirectionEnum } from "../../core/enums/enums";
import jwt from "jwt-decode";
import { Token } from "../../core/types/types";

interface HeaderMenuProps {
  handleLogOut: () => void;
}

export function HeaderMenu({ handleLogOut }: HeaderMenuProps) {
  const navigate = useNavigate();
  const token = sessionStorage.getItem("access_token") ?? "";
  const isAdmin = (jwt(token) as Token).is_admin;

  return (
    <div className="flex w-full flex-col items-center gap-2 justify-center">
      <DropDownMenuButtonItem
        label="My Profile"
        labelColorClass="text-[#272D31] hover:text-primary"
        action={() => navigate("/user/settings")}
        backgroundColorClass="hover:bg-primary/10"
        icon={<PiBookmarkSimple size={20} />}
        dir={DirectionEnum.LTR}
      />
      {isAdmin && (
        <DropDownMenuButtonItem
          label="Dashboard"
          labelColorClass="text-[#272D31] hover:text-primary"
          action={() => navigate("/dashboard")}
          backgroundColorClass="hover:bg-primary/10"
          icon={<PiChartDonut size={20} />}
          dir={DirectionEnum.LTR}
        />
      )}
      <DropDownMenuButtonItem
        label="Logout"
        labelColorClass="text-danger hover:text-danger"
        action={() => handleLogOut()}
        backgroundColorClass="hover:bg-danger/10"
        icon={<PiSignOut size={20} />}
        dir={DirectionEnum.LTR}
      />
    </div>
  );
}

export default HeaderMenu;
