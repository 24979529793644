import { CategoryType, FundingOpportunity } from "../../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import SharedPagination from "../../pagination/pagination";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  Status,
  VariantsEnum,
} from "../../../core/enums/enums";
import nihLogo from "../../../assets/nih-logo.svg";
import {
  createCategoryService,
  deleteCategoryService,
  fetchCategoriesListService,
  fetchOpportunitiesService,
} from "../../../core/services/shared.service";
import { useFormik } from "formik";
import { InputField } from "../../input-field/input-field";
import { FiPlus, FiX } from "react-icons/fi";
import { Button } from "../../button";
import { validationSchema } from "./categories_list.validation";
import useIsMobileScreen from "../../../core/hooks/use-is-mobile-screen.helpers";
import useSeedDataStore, {
  SeedDataStoreType,
} from "../../../core/stores/seed.store";
import QuickSearchInput from "../../quick-search/quick-search";

export default function CategoriesList() {
  const [opportunities, setOpportunities] = useState<FundingOpportunity[]>([]);
  const [categoriesList, setCategoriesList] = useState<CategoryType[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string>("");
  const limit = 10;
  const { isMobile } = useIsMobileScreen();
  const { setCategories }: SeedDataStoreType = useSeedDataStore();

  const formik = useFormik({
    initialValues: {
      name: "",
    },
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const fetchOpportunities = useCallback(async () => {
    try {
      const res = await fetchOpportunitiesService({
        limit: limit,
        offset: limit * (currentPage - 1),
        q: searchValue,
      });

      setOpportunities(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  }, [currentPage, searchValue]);

  const fetchCategories = useCallback(async () => {
    try {
      const res = await fetchCategoriesListService();
      setCategoriesList(res);
      setCategories(res);
    } catch (error) {
      toast_error(error);
    }
    setLoading(false);
  }, [setCategories]);

  const removeCategory = async (id: string) => {
    try {
      await deleteCategoryService(id);
      fetchCategories();
    } catch (error) {
      toast_error(error);
    }
  };

  const handleSubmit = async () => {
    try {
      await createCategoryService({ name: formik.values.name });
      formik.resetForm();
      fetchCategories();
    } catch (error) {
      toast_error(error);
    }
  };

  useEffect(() => {
    setLoading(true);
    fetchOpportunities();
    fetchCategories();
  }, [fetchCategories, fetchOpportunities]);

  if (loading)
    return (
      <div className="w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  return (
    <div className="w-full">
      <p className="text-black font-bold text-4xl mb-5">Categories</p>
      <div className="lg:flex gap-4 mb-6">
        <div className="flex flex-col w-full lg:w-[426px] flex-shrink-0 bg-[#F7FAFC] rounded-[20px] p-8 h-fit mb-6">
          <div className="w-full flex-wrap flex gap-3 items-center justify-center mb-4">
            {categoriesList?.map((e, i) => (
              <div
                className="bg-[#F2F5F7] rounded-xl p-3 text-[#272D31] text-xs flex w-fit items-center gap-2 justify-center"
                key={`categories-${i}`}
              >
                <span>{e.name}</span>
                <FiX
                  size={16}
                  className="cursor-pointer"
                  onClick={() => removeCategory(e.id ?? "")}
                />
              </div>
            ))}
          </div>
          <div className="border border-[#E2E2E2] rounded-2xl w-full p-4">
            <form
              onSubmit={formik.handleSubmit}
              className="w-full flex flex-col gap-3"
            >
              <InputField
                placeholder="Write ..."
                aria-label="Add Category "
                name="name"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                dir={DirectionEnum.LTR}
                value={formik.values.name.trimStart() ?? ""}
                isValid={formik.touched.name && !formik.errors.name}
                error={
                  formik.touched.name && formik.errors.name
                    ? formik.errors.name
                    : ""
                }
              />
              <div className="flex justify-start w-full">
                <Button
                  color={ColorsEnum.Primary}
                  variant={VariantsEnum.Filled}
                  size={isMobile ? SizesEnum.Small : SizesEnum.Large}
                  type="submit"
                  className="font-bold !rounded-3xl"
                >
                  <div className="flex items-center gap-1 justify-center">
                    <FiPlus />
                    <span>Add</span>
                  </div>
                </Button>
              </div>
            </form>
          </div>
        </div>
        <div className="flex flex-col gap-3 w-full">
          <div className="flex w-full items-center justify-between gap-2">
            <div className="text-[#64748B] font-bold text-sm">
              Result : {totalCount}
            </div>
            <QuickSearchInput
              value={searchValue}
              onSearchValueChange={(newSearch) => {
                setSearchValue(newSearch);
              }}
            />
          </div>
          {opportunities?.length > 0 ? (
            opportunities.map((e, i) => (
              <div
                className="flex gap-2 items-center w-full"
                key={`opportunities-${i}`}
              >
                <div
                  className="rounded-2xl group cursor-pointer w-full flex flex-col gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
                  key={e.id}
                >
                  <div className="grid grid-cols-5 items-center gap-2">
                    <p className="col-span-3 line-clamp-2 font-bold text-[#272D31] text-xs group-hover:text-[#0C6BAF]">
                      {e.title}
                    </p>
                    <p className="text-[#272D31] text-xs font-bold text-end">
                      {new Date(e.published).toDateString()}
                    </p>
                    <div
                      className={`text-[10px] text-center ml-auto px-2 py-1 w-fit bg-opacity-10 font-bold rounded-2xl ${
                        e.status === Status.Visible
                          ? "text-[#0BBB87] bg-[#0BBB87]"
                          : "text-[#E61010] bg-[#E61010]"
                      }`}
                    >
                      {e.status}
                    </div>
                  </div>

                  <div className="flex w-full items-center justify-between h-12">
                    <div className="flex items-center gap-2">
                      <div className="text-[#64748B] text-xs w-fit flex gap-2 items-center">
                        <img src={nihLogo} alt="" className="h-5 w-auto" />
                        <span>U.S. NIH Platform</span>
                      </div>
                      {e.type !== "NORMAL" && (
                        <div className="bg-[#01487B] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#0C6BAF] text-[10px]">
                          {e.type}
                        </div>
                      )}
                      {e.tags?.map((e) => (
                        <p
                          key={`${e}`}
                          className="bg-[#BC5C25] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#E96215] text-[10px]"
                        >
                          {e}
                        </p>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))
          ) : (
            <div className="w-full text-center">No Data</div>
          )}

          <div className="w-full mx-auto mb-4">
            <SharedPagination
              limit={limit}
              totalListCount={totalCount}
              onPageClick={(page: number) => {
                setCurrentPage(page);
              }}
              currentPage={currentPage}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
