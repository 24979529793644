import { makeRequest, urls } from "../http";
import { removeFalsyValues } from "../hooks/shared.helpers";
import { HttpMethods } from "../enums/httpMethods.enum";
import {
  CategoryType,
  CreateOrUpdateOpportunityData,
  DynamicValuesType,
} from "../types/types";

export const fetchUsersDateByAdminService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getUsersByAdmin,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const giveUserAdminPermissionService = (data: {
  users_ids: string[];
  make_admin: boolean;
}) => {
  return makeRequest({
    url: urls.shared.giveAdminPermission,
    method: HttpMethods.POST,
    data,
  });
};

export const fetchOpportunityByIdService = (opportunityId: string) => {
  return makeRequest({
    url: urls.shared.getOpportunityById(opportunityId),
    method: HttpMethods.GET,
  });
};

export const fetchPublicOpportunityByIdService = (opportunityId: string) => {
  return makeRequest({
    url: urls.shared.getPublicOpportunityById(opportunityId),
    method: HttpMethods.GET,
  });
};

export const createOpportunityByIdService = (
  data: CreateOrUpdateOpportunityData
) => {
  return makeRequest({
    url: urls.shared.addOpportunity,
    method: HttpMethods.POST,
    data,
  });
};

export const updateOpportunityByIdService = (
  opportunityId: string,
  data: { tags: string[] }
) => {
  return makeRequest({
    url: urls.shared.getOpportunityById(opportunityId),
    method: HttpMethods.PUT,
    data,
  });
};

export const fetchFeedbackListService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getFeedbackList,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchHistoryListService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getHistoryList,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchOpportunityListService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getAllOpportunity,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const updateOpportunityVisibilityService = (data: {
  opportunities_ids: string[];
  status: string;
}) => {
  return makeRequest({
    url: urls.shared.updateOpportunityVisibility,
    method: HttpMethods.POST,
    data,
  });
};

export const updateUserDataService = (data: any) => {
  return makeRequest({
    url: urls.shared.updateUser,
    method: HttpMethods.PUT,
    data,
  });
};

export const fetchUserDataService = () => {
  return makeRequest({
    url: urls.shared.getUser,
    method: HttpMethods.GET,
  });
};

export const loginService = (data: { email: string; password: string }) => {
  return makeRequest({
    url: urls.shared.login,
    method: HttpMethods.POST,
    data,
  });
};

export const signupService = (data: {
  first_name: string;
  last_name: string;
  mobile: string;
  country_code: string;
  nationality: string;
  education: string;
  institution: string;
  email: string;
  password: string;
  confirm_password: string;
}) => {
  return makeRequest({
    url: urls.shared.signup,
    method: HttpMethods.POST,
    data,
  });
};

export const forgetPasswordService = (data: { email: string }) => {
  return makeRequest({
    url: urls.shared.forgetPassword,
    method: HttpMethods.POST,
    data,
  });
};

export const resetPasswordService = (data: {
  new_password: string;
  confirm_new_password: string;
  token: string;
}) => {
  return makeRequest({
    url: urls.shared.resetPassword,
    method: HttpMethods.POST,
    data,
  });
};

export const submitFeedbackService = (data: { text: string }) => {
  return makeRequest({
    url: urls.shared.submitFeedback,
    method: HttpMethods.POST,
    data,
  });
};

export const fetchUserInterestsService = (params?: DynamicValuesType) => {
  return makeRequest({
    url: urls.shared.getUserInterests,
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const updateUserInterestedService = (data: {
  funding_id: string;
  interested: boolean | null;
  reminder_date?: string;
}) => {
  return makeRequest({
    url: urls.shared.userInterested,
    method: HttpMethods.POST,
    data: { ...removeFalsyValues(data) },
  });
};

export const fetchOpportunitiesService = (
  params?: DynamicValuesType,
  categoriesParams?: string
) => {
  return makeRequest({
    url:
      urls.shared.getOpportunities +
      (categoriesParams ? "?" + categoriesParams : ""),
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchAdminHistoryService = (
  userId: string,
  params?: DynamicValuesType
) => {
  return makeRequest({
    url: urls.shared.getAdminHistory(userId),
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const fetchUserInterestService = (
  userId: string,
  params?: DynamicValuesType
) => {
  return makeRequest({
    url: urls.shared.getUserInterest(userId),
    method: HttpMethods.GET,
    params: { ...removeFalsyValues(params || {}) },
  });
};

export const countShareOpportunityService = (opportunityId: string) => {
  return makeRequest({
    url: urls.shared.countShareClick(opportunityId),
    method: HttpMethods.POST,
  });
};

export const fetchCategoriesListService = () => {
  return makeRequest({
    url: urls.shared.getAllCategories,
    method: HttpMethods.GET,
  });
};

export const createCategoryService = (data: CategoryType) => {
  return makeRequest({
    url: urls.shared.createCategory,
    method: HttpMethods.POST,
    data,
  });
};

export const deleteCategoryService = (categoryId: string) => {
  return makeRequest({
    url: urls.shared.deleteCategory(categoryId),
    method: HttpMethods.DELETE,
  });
};

export const fetchSeedDataService = () => {
  return makeRequest({
    url: urls.shared.seed,
    method: HttpMethods.GET,
  });
};
