import { FundingOpportunity, User } from "../../core/types/types";
import { useState, useEffect, useCallback } from "react";
import { toast_error } from "../../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import { ColorsEnum, VariantsEnum, SizesEnum } from "../../core/enums/enums";
import { Button } from "../button";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { PiPencilSimpleLineBold } from "react-icons/pi";
import {
  fetchAdminHistoryService,
  fetchUsersDateByAdminService,
  giveUserAdminPermissionService,
} from "../../core/services/shared.service";
import { useLocation, useNavigate } from "react-router-dom";
import SearchInput from "../search";
import SharedPagination from "../pagination/pagination";

export default function AdminsList() {
  const location = useLocation();
  const navigate = useNavigate();
  const [users, setUsers] = useState<User[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [isSideMenuOpen, setIsSideMenuOpen] = useState<boolean>(false);
  const [adminHistoryData, setAdminHistoryData] = useState<
    {
      admin: User;
      entity: FundingOpportunity | User;
      action: string;
      created: string;
    }[]
  >([]);
  const [searchValue, setSearchValue] = useState<string>(
    new URLSearchParams(location.search).get("q") || ""
  );
  const [totalCount, setTotalCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const limit = 10;

  const fetchUsers = useCallback(async () => {
    setLoading(true);
    const url = new URLSearchParams(location.search);
    url.delete("q");

    if (searchValue) {
      url.append("q", searchValue);
    }

    navigate(
      {
        pathname: location.pathname,
        search: url.toString(),
      },
      { replace: true }
    );

    try {
      const res = await fetchUsersDateByAdminService({
        is_admin: true,
        limit: 100,
        offset: 0,
        q: searchValue,
      });
      setUsers(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, [location.pathname, location.search, navigate, searchValue]);

  const giveAdminPermission = async (user_id: string, make_admin: boolean) => {
    setLoading(true);
    try {
      await giveUserAdminPermissionService({
        users_ids: [user_id],
        make_admin: make_admin,
      });
      fetchUsers();
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  };

  const getAdminHistory = async (userId: string) => {
    try {
      const res = await fetchAdminHistoryService(userId, {
        limit: 100,
        offset: 0,
      });
      setAdminHistoryData(res.result_list);
      setIsSideMenuOpen(true);
    } catch (error) {
      toast_error(error);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  if (loading)
    return (
      <div className="w-full text-center">
        <ClipLoader className="mx-auto mt-10" />
      </div>
    );

  return (
    <div className="w-full">
      <div className="flex gap-4 flex-col mb-6">
        <p className="text-black font-bold lg:text-4xl">Admins</p>
        <div className="flex items-center justify-between w-full">
          <div className="text-[#64748B] font-bold text-sm">
            Result : {users.length}
          </div>
          <SearchInput
            value={searchValue}
            onSearchValueChange={(newSearch) => {
              setSearchValue(newSearch);
            }}
          />
        </div>
        {users.length > 0 ? (
          users.map((e) => (
            <div
              className="rounded-2xl group cursor-pointer grid items-center lg:grid-cols-7 gap-3 px-5 py-4 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
              key={e.id}
            >
              <p className="text-sm text-[#272D31] group-hover:text-[#0C6BAF]">
                {e.first_name}
              </p>
              <p className="text-sm text-[#272D31] group-hover:text-[#0C6BAF]">
                {e.last_name}
              </p>
              <p className="lg:col-span-2 text-sm text-[#272D31] group-hover:text-[#0C6BAF]">
                {e.email}
              </p>
              <div className="text-sm text-center text-[#E61010] px-2 py-1 w-fit bg-[#E61010] bg-opacity-10 font-bold rounded-2xl">
                Admin
              </div>
              <div className="flex group-hover:visible invisible justify-end w-full lg:col-span-2">
                <Button
                  color={ColorsEnum.Primary}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Small}
                  className="font-bold !rounded-3xl"
                  onClick={() => getAdminHistory(e.id)}
                >
                  <div className="flex items-center gap-1 justify-center">
                    <span>View </span>
                    <FiChevronRight />
                  </div>
                </Button>
              </div>
            </div>
          ))
        ) : (
          <div className="w-full text-center">No Data</div>
        )}
        <div className="w-full mx-auto mb-4">
          <SharedPagination
            limit={limit}
            totalListCount={totalCount}
            onPageClick={(page: number) => {
              setCurrentPage(page);
            }}
            currentPage={currentPage}
          />
        </div>
      </div>
      {isSideMenuOpen && adminHistoryData && (
        <>
          {/* <!--Overlay Effect--> */}
          <div className="z-[9999] fixed inset-0 min-h-full overflow-hidden bg-white/5 backdrop-blur-md" />
          <div className="z-[9999] fixed inset-0 flex h-full w-full items-center justify-center overflow-hidden bg-gray-600 bg-opacity-50">
            <div className="z-[9999] absolute right-0 h-screen w-10/12 lg:w-1/3 lg:p-12 p-6 overflow-x-hidden rounded-l-xl bg-white">
              <div className="flex items-center mb-7 justify-between w-full gap-3">
                <Button
                  color={ColorsEnum.Muted}
                  variant={VariantsEnum.Muted}
                  size={SizesEnum.Medium}
                  onClick={() => {
                    setIsSideMenuOpen(false);
                    setAdminHistoryData([]);
                  }}
                  className="font-bold !rounded-3xl"
                >
                  <div className="flex items-center gap-1 justify-center">
                    <FiChevronLeft />
                    <span>Back</span>
                  </div>
                </Button>

                <Button
                  color={ColorsEnum.Danger}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Medium}
                  className="font-bold !rounded-3xl"
                  onClick={() =>
                    giveAdminPermission(adminHistoryData[0].admin.id, false)
                  }
                >
                  <div className="flex items-center gap-1 justify-center">
                    <span>Remove Admin </span>
                    <PiPencilSimpleLineBold />
                  </div>
                </Button>
              </div>
              <div className="w-full flex flex-col gap-7 items-center">
                {adminHistoryData.map((history, index) => {
                  return (
                    <div
                      key={`history-${index}`}
                      className="group px-5 py-4 rounded-[20px] w-full flex flex-col gap-4 border border-[#E2E2E2] hover:border-transparent bg-transparent hover:bg-white transition-all duration-300"
                    >
                      <div className="ml-auto text-sm text-center text-[#E61010] px-2 py-1 w-fit bg-[#E61010] bg-opacity-10 font-bold rounded-2xl">
                        {history.action}
                      </div>
                      <div className="flex items-center gap-7 w-full">
                        <p className="text-sm font-bold text-[#272D31] group-hover:text-[#0C6BAF]">
                          {history.admin.first_name} {history.admin.last_name}
                        </p>
                        <p className="text-sm font-bold text-[#272D31] group-hover:text-[#0C6BAF]">
                          {history.admin.email}
                        </p>
                      </div>
                      <p className="text-sm line-clamp-2 text-ellipsis text-[#272D31] group-hover:text-[#0C6BAF]">
                        {history.entity && "email" in history.entity
                          ? history.entity.email
                          : history.entity && "title" in history.entity
                          ? history.entity.title
                          : ""}
                      </p>
                      <p className="text-[#8C9AA3] font-bold text-xs w-full text-end">
                        {new Date(history.created).toDateString()}
                      </p>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
}
