import Header from "../components/header/header";
import Footer from "../components/footer";
import placeholderImage from "../assets/placeholder.png";

function SaudiListPage() {
  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm max-w-xs mx-auto w-full lg:mt-20 mb-10">
          <p className="text-black font-bold text-4xl">
            Saudi NIH Opportunities
          </p>
        </div>
        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full">
          <div className="lg:w-[312px] w-full h-full hidden lg:flex flex-col gap-6 flex-shrink-0"></div>

          <div className="w-full">
            <div className="flex lg:flex-row flex-col items-start justify-between gap-6">
              <div className="text-[#64748B] font-bold text-sm">
                Result : {0}
              </div>
            </div>

            <div className="flex items-center flex-col gap-3 justify-center p-20">
              <img src={placeholderImage} alt="No Recent Update" />
              <p className="text-center font-bold text-black">
                No Recent Update ...
              </p>
            </div>
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />
    </div>
  );
}

export default SaudiListPage;
