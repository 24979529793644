import { Navigate } from "react-router-dom";
import Footer from "../components/footer";
import Header from "../components/header/header";
import SignUpContainer from "../containers/signup-container/signup-container";

export default function SignUpPage() {
  const token = sessionStorage.getItem("access_token") ?? null;

  if (token) {
    return <Navigate to="/" />;
  }

  return (
    <div className="lg:min-h-screen flex flex-col z-50 bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
      {/* HEADER */}
      <Header />

      <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-center lg:min-h-screen h-full py-10 gap-6 max-w-xs mx-auto w-full">
        <SignUpContainer />
      </div>

      <Footer />
    </div>
  );
}
