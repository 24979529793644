import { useCallback, useEffect, useState } from "react";
import { isExpired } from "react-jwt";
import { useLocation, useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";

interface TokenValidationWrapperContainerProps {
  children: JSX.Element;
}

const RefreshTokenUpdateWrapperContainer = ({
  children,
}: TokenValidationWrapperContainerProps) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [isRefreshing, setIsRefreshing] = useState<boolean>(false);

  const handleLogOut = useCallback(async () => {
    sessionStorage.setItem("access_token", "");
    navigate("/");
  }, [navigate]);

  useEffect(() => {
    const token = sessionStorage.getItem("access_token") as string;

    if (isExpired(token)) {
      setIsRefreshing(true);
      handleLogOut();
    }
  }, [handleLogOut, location]);

  if (isRefreshing) {
    return (
      <div className="flex justify-center h-full">
        <ClipLoader
          color={"#0284c7"}
          loading={true}
          aria-label="Loading Spinner"
          data-testid="loader"
        />
      </div>
    );
  }

  return children;
};

export default RefreshTokenUpdateWrapperContainer;
