import { toast_error } from "../../core/hooks/toast_error";
import { Link, useNavigate } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { validationSchema } from "./change-email-container.validation";
import { updateUserDataService } from "../../core/services/shared.service";
import useUserDataStore, {
  UserDataStoreType,
} from "../../core/stores/user.store";

export default function ChangeEmailContainer() {
  const navigate = useNavigate();
  const { userData, setRefreshUserData }: UserDataStoreType =
    useUserDataStore();

  const formik = useFormik({
    initialValues: {
      email: userData?.email ?? "",
    },
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      await updateUserDataService(formik.values);
      toast("Your Email has been changed!", {
        style: { backgroundColor: "#38a169", color: "white" },
      });
      navigate("/user/settings");
      setRefreshUserData(true);
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <Link
          to="/user/settings"
          className="flex items-center gap-2 text-[#0C6BAF] font-bold text-sm"
        >
          <FiChevronLeft size={20} />
          <span>Back</span>
        </Link>
        <p className="text-black font-bold text-4xl">Change Email</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <InputField
            placeholder="email@email.com"
            aria-label="New Email"
            type="email"
            name="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            dir={DirectionEnum.LTR}
            value={formik.values.email.trimStart() ?? ""}
            isValid={formik.touched.email && !formik.errors.email}
            error={
              formik.touched.email && formik.errors.email
                ? formik.errors.email
                : ""
            }
          />

          {/* ACTIONS */}
          <div className="flex  justify-end w-full">
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              disabled={!formik.dirty}
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Save Change</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
