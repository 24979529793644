import { FiChevronDown } from "react-icons/fi";
import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { DirectionEnum } from "../../core/enums/enums";

import lists from "./lists.json";

export interface MobileFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
  error?: string;
  handlePhoneCodeChange?: (code: string) => void;
  selectedCode?: string;
  fixedPrefix?: string; // to hide the list
}

export function MobileField({
  handlePhoneCodeChange,
  selectedCode,
  hint,
  error,
  isValid,
  fixedPrefix,
  ...props
}: MobileFieldProps): JSX.Element {
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "outline-none border-2 border-emerald-500 text-emerald-500 !bg-emerald-500 !bg-opacity-5";
      case false:
        return "outline-none border-2 border-red-500 text-red-500 !bg-red-500 !bg-opacity-5";
      default:
        return "border-2 border-transparent focus:border-2 focus:border-blue-400 focus:bg-white focus:outline-none";
    }
  };

  const getSelectValidationClass = () => {
    switch (isValid) {
      case true:
        return "border-2 border-r-0 border-r-transparent border-emerald-500 focus:!border-emerald-500";
      case false:
        return "border-2 border-r-0 border-r-transparent border-red-500 focus:!border-red-500";
      default:
        return "group-focus-within:border-2 border-transparent group-focus-within:border-r-0 group-focus-within:border-r-transparent focus:!border-transparent";
    }
  };

  const getValidationIcon = () => {
    switch (isValid) {
      case true:
        return <HiOutlineCheckCircle className="text-base text-emerald-500" />;
      case false:
        return (
          <HiOutlineExclamationCircle className="text-base text-red-500" />
        );
      default:
        return;
    }
  };

  const getErrorClass = () => {
    switch (isValid) {
      case true:
        return "text-emerald-500";
      case false:
        return "text-red-500";
      default:
        return "text-slate-500 dark:text-slate-300";
    }
  };

  return (
    <div className={`mb-2 w-full ${props.disabled ? "opacity-40" : ""}`}>
      {props["aria-label"] && (
        <label className={`mb-2 block text-sm font-bold ${getErrorClass()}`}>
          {props["aria-label"]}
        </label>
      )}

      <div className="group relative py-2">
        <div className="absolute left-0 top-2 h-12 w-fit">
          {fixedPrefix ? (
            <div className="flex h-full w-24 items-center justify-center text-center text-base font-bold text-slate-500 dark:text-white">
              {fixedPrefix}
            </div>
          ) : (
            <>
              <FiChevronDown className="absolute right-3 top-4 z-30 text-base text-slate-500 dark:text-slate-300" />
              <select
                title="phone_codes"
                dir={DirectionEnum.LTR}
                className={`h-full w-full appearance-none rounded-l-xl border-2 bg-slate-100 py-2 pl-3 pr-8 font-bold text-slate-500 ring-0 ring-transparent focus:outline-none focus:ring-0 focus:ring-transparent group-focus-within:border-blue-400 dark:bg-slate-700 dark:text-slate-300 ${getSelectValidationClass()}`}
                onChange={(e) => {
                  if (handlePhoneCodeChange) {
                    handlePhoneCodeChange(e.target.value);
                  }
                }}
                value={selectedCode}
              >
                {lists.phone_codes.map((code: string, index: number) => {
                  return (
                    <option value={code} key={`code-${index}`}>
                      {code}
                    </option>
                  );
                })}
              </select>
            </>
          )}
        </div>
        <input
          {...props}
          className={`h-fit w-full appearance-none rounded-xl bg-gray-500/5 py-2 pl-28 pr-4 text-base leading-7 text-slate-500 ring-0 ring-transparent placeholder:text-slate-500 placeholder:opacity-40 focus:border-blue-400 focus:shadow-md focus:ring-0 focus:ring-transparent dark:bg-slate-800 dark:text-white dark:placeholder:text-white dark:focus:shadow-none ${getValidationClass()}
          ${props.className ?? ""}`}
        />
        <div
          className={`absolute top-6 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-4" : "right-4"
          }`}
        >
          {getValidationIcon()}
        </div>
      </div>
      {hint && (
        <p className="text-xs text-slate-400 opacity-60 dark:text-slate-300">
          {hint}
        </p>
      )}
      {error && <p className={`text-xs ${getErrorClass()}`}>{error}</p>}
    </div>
  );
}
