export const educations = [
  {
    name: "High School",
  },
  {
    name: "Bachelor",
  },
  {
    name: "Masters",
  },
  {
    name: "PhD",
  },
];
