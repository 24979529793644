import { Navigate } from "react-router-dom";
import Header from "../components/header/header";
import LoginContainer from "../containers/login-container/login-container";
import Footer from "../components/footer";
import { isExpired } from "react-jwt";

export default function LoginPage() {
  const token = sessionStorage.getItem("access_token") ?? "";

  if (!isExpired(token)) {
    return <Navigate to="/" />;
  }

  return (
    <div className="h-screen flex flex-col z-50 bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
      {/* HEADER */}
      <Header />

      <div className="lg:max-w-7xl md:max-w-sm min-h-[90vh] flex items-center justify-center max-w-xs mx-auto w-full">
        <LoginContainer />
      </div>

      <Footer />
    </div>
  );
}
