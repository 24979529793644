import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object({
    title: Yup.string().required("الزامي"),
    description: Yup.string().required("الزامي"),
    link: Yup.string().required("الزامي"),
    published: Yup.string().required("الزامي"),
    tags: Yup.string().required("الزامي"),
  });
};
