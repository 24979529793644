import { ColorsEnum, VariantsEnum, SizesEnum } from "../core/enums/enums";
import { FundingOpportunity } from "../core/types/types";
import { Button } from "./button";
import { BiShareAlt } from "react-icons/bi";
import nihLogo from "../assets/nih-logo.svg";
import { FiCheck } from "react-icons/fi";
import { countShareOpportunityService } from "../core/services/shared.service";
import { toast_error } from "../core/hooks/toast_error";
import { isExpired } from "react-jwt";

type CardProps = {
  data: FundingOpportunity;
  interested?: boolean;
  setSelectedId: (value: string) => void;
};

export default function Card({ data, interested, setSelectedId }: CardProps) {
  const token = sessionStorage.getItem("access_token");
  const isLoggedIn = token ? !isExpired(token) : false;

  const copyLink = () => {
    navigator.clipboard.writeText(
      `${process.env["REACT_APP_FRONTEND_BASE_URL"]}/opportunities/${data?.id}`
    );

    if (isLoggedIn) {
      handleShare();
    }
  };

  const handleShare = async () => {
    try {
      await countShareOpportunityService(data.id);
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <div
      key={data.id}
      onClick={() => setSelectedId(data.id)}
      className="rounded-2xl relative cursor-pointer flex flex-col gap-5 p-8 border border-[#E2E2E2] hover:border-white h-full hover:bg-white bg-[#F2F5F7] transition-all duration-300"
    >
      {interested && (
        <div className="absolute flex items-center justify-center gap-1 w-fit -top-3 left-10 bg-[#0BBB87] rounded-[20px] p-1 px-2 text-white text-xs font-bold">
          <span>Interested</span>
          <FiCheck />
        </div>
      )}

      <div className="flex justify-between items-center w-full">
        <div className="text-[#64748B] text-xs w-fit flex gap-2 items-center">
          <img src={nihLogo} alt="" className="h-5 w-auto" />
          <span>U.S. NIH Platform</span>
        </div>

        <div className="flex gap-2 h-9 items-center justify-end flex-wrap w-fit">
          {data.type !== "NORMAL" && (
            <div className="bg-[#01487B] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#0C6BAF] text-[10px]">
              {data.type}
            </div>
          )}
          {data.tags?.map((e) => (
            <p
              key={`${e}`}
              className="bg-[#BC5C25] bg-opacity-10 rounded-3xl py-1 px-2 font-bold w-fit text-[#E96215] text-[10px]"
            >
              {e}
            </p>
          ))}
        </div>
      </div>

      <p className="line-clamp-2 text-ellipsis text-xl text-slate-700 hover:text-blue-400 font-bold transition-all duration-150">
        {data.title}
      </p>

      <p className="line-clamp-3 h-12 text-ellipsis text-[#64748B] text-xs">
        {data.description}
      </p>

      <div className="flex justify-between items-center w-full">
        <div>
          <p className="text-xs text-[#AEB7BD]">
            Published:{" "}
            <span className="text-[#8C9AA3] font-bold text-xs">
              {new Date(data.publish_date).toDateString()}
            </span>
          </p>
          {data.due_date && (
            <p className="text-xs text-[#AEB7BD]">
              Due date:{" "}
              <span className="text-[#8C9AA3] font-bold text-xs">
                {new Date(data.due_date).toDateString()}
              </span>
            </p>
          )}
        </div>
        <div>
          <Button
            color={ColorsEnum.Muted}
            variant={VariantsEnum.Muted}
            size={SizesEnum.Small}
            className="font-bold"
            onClick={(e) => {
              e.preventDefault();
              copyLink();
            }}
          >
            <div className="flex items-center gap-1 justify-center">
              <span>Share</span>
              <BiShareAlt />
            </div>
          </Button>
        </div>
      </div>
    </div>
  );
}
