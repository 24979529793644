import * as Yup from "yup";

export const validationSchema = () => {
  return Yup.object({
    first_name: Yup.string().required("Required"),
    last_name: Yup.string().required("Required"),
    country_code: Yup.string().required("Required"),
    nationality: Yup.string().required("Required"),
    education: Yup.string().required("Required"),
    institution: Yup.string().required("Required"),
    mobile: Yup.string()
      .required("Required")
      .matches(/^\d+$/, "Only numbers are allowed")
      .min(6, "Minimum 6 numbers")
      .max(15, "Maximum 15 numbers")
      .when("country_code", {
        is: (val: string) => val === "+966",
        then: (schema) =>
          schema
            .min(9, "Minimum 9 numbers")
            .max(9, "Maximum 9 numbers")
            .matches(
              /^5[0-9]+$/,
              "Mobile number should start with 5 and contain only numbers"
            ),
      }),
  });
};
