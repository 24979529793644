import { SizesEnum, ColorsEnum } from "../../core/enums/enums";

export interface CheckboxButtonProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  value?: string | number | readonly string[] | undefined;
  button_size?: SizesEnum;
  indeterminate?: boolean;
  color?: ColorsEnum;
}

export function CheckboxButton({
  indeterminate = false,
  color = ColorsEnum.Primary,
  ...props
}: CheckboxButtonProps): JSX.Element {
  const getCheckboxSize = (size?: SizesEnum) => {
    switch (size) {
      case SizesEnum.Small:
        return "h-4 w-4";
      case SizesEnum.Medium:
        return "h-5 w-5";
      case SizesEnum.Large:
        return "h-6 w-6";
      default:
        return "h-6 w-6";
    }
  };

  const getLabelSize = (size?: SizesEnum) => {
    switch (size) {
      case SizesEnum.Small:
        return "caption";
      case SizesEnum.Medium:
        return "body-small";
      case SizesEnum.Large:
        return "body";
      default:
        return "";
    }
  };

  return (
    <div hidden={props.hidden} dir={props.dir} className="my-auto">
      <label htmlFor={props?.id} className="inline-flex items-center">
        <input
          {...props}
          ref={(input: HTMLInputElement) => {
            if (input && indeterminate) {
              input.indeterminate = indeterminate;
            }
          }}
          type="checkbox"
          className={`form-checkbox border-slate-400 checked:bg-${color} checked:border-${color} indeterminate:bg-${color} indeterminate:border-${color} focus:ring-${color} 
          hover:border-${color}-600 checked:hover:bg-${color}-600 focus:outline-${color}-700 indeterminate:focus:outline-${color}-700
           disabled:ring-muted rounded-b rounded-t border-2 focus:border-transparent focus:ring-1 disabled:opacity-40 ${getCheckboxSize(
             props.button_size
           )} ${props.className}`}
        />
        {props["aria-label"] && (
          <span
            className={`mx-3 mb-1 inline-block font-bold text-sm ${getLabelSize(
              props.button_size
            )}`}
          >
            {props["aria-label"]}
          </span>
        )}
      </label>
    </div>
  );
}
