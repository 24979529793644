import { ErrorType, ValidationErrorDetailType } from "../types/types";
import { toast } from "react-toastify";

// TODO: needs a lot of refactoring.
export const toast_error = (error: any) => {
  const error_message = (error as ErrorType).response.data.detail;
  const status = (error as ErrorType).response.status;
  if (status === 422) {
    let message = `${(error_message[0] as ValidationErrorDetailType).loc[1]} ${
      (error_message[0] as ValidationErrorDetailType).msg
    }`;

    if ((error_message[0] as ValidationErrorDetailType).loc[1] === "password") {
      message =
        "password must at least have one uppercase one lowercase and one number and one special character";
    }

    toast.error(message);
  } else {
    if (status === 401) {
      sessionStorage.removeItem("access_token");
    }

    toast.error(error_message as string);
  }
};
