import {
  HiOutlineCheckCircle,
  HiOutlineExclamationCircle,
} from "react-icons/hi";
import { DirectionEnum } from "../../core/enums/enums";

export interface InputFieldProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  isValid?: boolean;
  hint?: string;
  error?: string;
  startIcon?: JSX.Element;
  endIcon?: JSX.Element;
}

export function InputField({
  hint,
  error,
  isValid,
  startIcon,
  endIcon,
  ...props
}: InputFieldProps): JSX.Element {
  const getValidationClass = () => {
    switch (isValid) {
      case true:
        return "outline-none border-2 border-emerald-500 text-emerald-500 !bg-emerald-500 !bg-opacity-5";
      case false:
        return "outline-none border-2 border-red-500 text-red-500 !bg-red-500 !bg-opacity-5";
      default:
        return "border-2 border-transparent focus:border-2 focus:bg-white focus:outline-none";
    }
  };

  const getValidationPaddingClass = () => {
    switch (isValid) {
      case true:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      case false:
        return props.dir === DirectionEnum.RTL ? "pr-4 pl-10" : "pl-4 pr-10";
      default:
        return "px-4";
    }
  };

  const getValidationIcon = () => {
    switch (isValid) {
      case true:
        return <HiOutlineCheckCircle className="text-base text-emerald-500" />;
      case false:
        return (
          <HiOutlineExclamationCircle className="text-base text-red-500" />
        );
      default:
        return;
    }
  };

  const getErrorClass = () => {
    switch (isValid) {
      case true:
        return "text-emerald-500";
      case false:
        return "text-red-500";
      default:
        return "text-slate-500 dark:text-slate-300";
    }
  };

  return (
    <div className={`mb-2 w-full ${props.disabled ? "opacity-40" : ""}`}>
      {props["aria-label"] && (
        <label className={`mb-2 block text-sm font-bold ${getErrorClass()}`}>
          {props["aria-label"]}
        </label>
      )}

      <div className="relative py-2">
        {Boolean(startIcon) && (
          <div
            className={`absolute top-6 ${
              props.dir === DirectionEnum.LTR ? "left-4" : "right-4"
            }`}
          >
            {startIcon}
          </div>
        )}
        <input
          {...props}
          className={`h-fit w-full appearance-none rounded-xl bg-gray-500/5 py-2 text-base text-slate-500 ring-0 ring-transparent placeholder:text-slate-500 placeholder:opacity-40 focus:border-blue-400 focus:shadow-md focus:ring-0 focus:ring-transparent dark:bg-slate-800 dark:text-white dark:placeholder:text-white dark:focus:shadow-none ${getValidationClass()}
          ${props.className ?? ""}
          ${startIcon ? "px-12" : getValidationPaddingClass()}
          `}
        />
        <div
          className={`absolute top-6 flex items-center justify-center space-x-1 ${
            props.dir === DirectionEnum.RTL ? "left-4" : "right-4"
          }`}
        >
          {endIcon}
          {getValidationIcon()}
        </div>
      </div>
      {hint && (
        <p className="text-xs text-slate-400 opacity-60 dark:text-slate-300">
          {hint}
        </p>
      )}
      {error && <p className={`text-xs ${getErrorClass()}`}>{error}</p>}
    </div>
  );
}
