import { useEffect, useRef, useState } from "react";
import { ColorsEnum } from "../../core/enums/enums";

export type DropDownMenuType = {
  label: string;
  element?: JSX.Element;
  action: () => void;
  icon?: JSX.Element;
  color?: ColorsEnum;
};

export interface DropDownMenuProps {
  children: JSX.Element;
  listChildren: JSX.Element;
  isShown?: boolean;
}

export const DropDownMenu = ({
  listChildren,
  children,
  isShown,
}: DropDownMenuProps) => {
  const [isDropDownMenuOpen, setIsDropDownMenuOpen] = useState(false);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const menuRef = useRef<any>();

  useEffect(() => {
    const handleClickedOutside = (e: MouseEvent) => {
      // If the menu is open and the clicked target is not within the menu,
      // then close the menu
      if (
        isDropDownMenuOpen &&
        menuRef.current &&
        !menuRef.current.contains(e.target)
      ) {
        setIsDropDownMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickedOutside);

    return () => {
      // Cleanup the event listener
      document.removeEventListener("mousedown", handleClickedOutside);
    };
  }, [isDropDownMenuOpen]);

  useEffect(() => {
    if (typeof isShown === "boolean") {
      setIsDropDownMenuOpen(isShown);
    }
  }, [isShown]);

  return (
    <div className="relative hover:cursor-pointer">
      <div
        onClick={(e) => {
          e.preventDefault();
          setIsDropDownMenuOpen(!isDropDownMenuOpen);
        }}
      >
        {children}
      </div>
      {isDropDownMenuOpen && (
        <div ref={menuRef}>
          {listChildren && (
            <div
              className="absolute left-0 top-11 z-50 min-h-12 w-max rounded-2xl bg-white p-4 shadow transition-all sm:w-48 sm:min-w-fit"
              onClick={(e) => {
                e.preventDefault();
                setIsDropDownMenuOpen(false);
              }}
            >
              {listChildren}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
