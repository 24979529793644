import { useState, useEffect, useCallback } from "react";
import { FundingOpportunity } from "../core/types/types";
import { toast_error } from "../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import SharedPagination from "../components/pagination/pagination";
import Header from "../components/header/header";
import Card from "../components/card";
import Footer from "../components/footer";
import {
  AvailabilityEnum,
  ColorsEnum,
  DirectionEnum,
  ModalSizeEnum,
  SizesEnum,
  Tags,
  VariantsEnum,
} from "../core/enums/enums";
import { useLocation, useNavigate } from "react-router-dom";
import { unstable_batchedUpdates } from "react-dom";
import { MdOutlineCheck } from "react-icons/md";
import { InputField } from "../components/input-field/input-field";
import Modal from "../components/modal/modal-component";
import { Button } from "../components/button";
import { PiSlidersHorizontalDuotone } from "react-icons/pi";
import {
  fetchOpportunitiesService,
  fetchSeedDataService,
} from "../core/services/shared.service";
import QuickSearchInput from "../components/quick-search/quick-search";
import useSeedDataStore, { SeedDataStoreType } from "../core/stores/seed.store";
import { convertArrayParamToString } from "../core/hooks/shared.helpers";
import placeholderImage from "../assets/placeholder.png";
import QuickSearchMenu from "../components/quick-search/quick-search.menu";

function ArchivedListPage() {
  const navigate = useNavigate();
  const limit = 12;
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [cards, setCards] = useState<FundingOpportunity[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [totalCount, setTotalCount] = useState<number>(0);
  const [loading, setLoading] = useState<boolean>(true);
  const [selectedCategories, setSelectedCategories] = useState<string[]>(
    new URLSearchParams(location.search).getAll("categories") ?? []
  );
  const [searchValue, setSearchValue] = useState<string>(
    new URLSearchParams(location.search).get("q") || ""
  );
  const [selectedDate, setSelectedDate] = useState<string>(
    new URLSearchParams(location.search).get("published") ?? ""
  );
  const [selectedFilter, setSelectedFilter] = useState<Tags | string>(
    new URLSearchParams(location.search).get("eligibility") ?? ""
  );
  const { setCategories, categories }: SeedDataStoreType = useSeedDataStore();

  const fetchSeedData = useCallback(async () => {
    try {
      const res = await fetchSeedDataService();
      setCategories(res.categories);
    } catch (error) {
      toast_error(error);
    }
  }, [setCategories]);

  const fetchData = useCallback(async () => {
    setLoading(true);
    const url = new URLSearchParams(location.search);
    url.delete("q");
    url.delete("eligibility");
    url.delete("published");
    url.delete("categories");

    if (searchValue) {
      url.append("q", searchValue);
    }

    if (selectedFilter) {
      url.append("eligibility", selectedFilter);
    }


    if (selectedDate) {
      url.append("published", selectedDate);
    }

    if (selectedCategories.length > 0) {
      selectedCategories.forEach((e) => {
        url.append("categories", e);
      });
    }

    navigate(
      {
        pathname: location.pathname,
        search: url.toString(),
      },
      { replace: true }
    );

    try {
      const res = await fetchOpportunitiesService(
        {
          limit,
          offset: limit * (currentPage - 1),
          tag: selectedFilter,
          availability: AvailabilityEnum.CLOSED,
          from_date: selectedDate
            ? new Date(selectedDate || "").toISOString()
            : "",
          to_date: selectedDate
            ? new Date(selectedDate || "").toISOString()
            : "",
          q: searchValue,
        },
        convertArrayParamToString("categories", selectedCategories)
      );

      setCards(res.result_list);
      setTotalCount(res.total_count);
    } catch (error) {
      setCards([]);
      toast_error(error);
    }
    setLoading(false);
  }, [
    currentPage,
    location.pathname,
    location.search,
    navigate,
    searchValue,
    selectedCategories,
    selectedDate,
    selectedFilter,
  ]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (categories?.length === 0) {
      fetchSeedData();
    }
  }, [categories?.length, fetchSeedData]);

  return (
    <div className="h-screen">
      <div className="min-h-screen bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm max-w-xs mx-auto w-full lg:mt-20 mb-10">
          <p className="text-black font-bold text-4xl">U.S NIH Archived Opportunities</p>
        </div>
        <div className="lg:max-w-7xl md:max-w-sm flex lg:flex-row flex-col items-start gap-6 max-w-xs mx-auto w-full">
          <div className="lg:w-[312px] w-full h-full hidden lg:flex flex-col gap-6 flex-shrink-0">
            <div className="flex items-center justify-between w-full">
              <p className="text-[#64748B] font-bold text-sm h-[45px]">
                Filters
              </p>
              {(selectedFilter !== "" ||
                selectedDate !== "") && (
                <button
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedFilter("");
                    setSelectedDate("");
                  }}
                  className="text-[#0C6BAF] font-bold text-xs cursor-pointer"
                >
                  Clear All
                </button>
              )}
            </div>
            <div className="p-4 bg-white w-full rounded-[20px]">
              <div className="flex flex-col gap-1">
                <p className="text-black text-[10px] font-bold">Eligibility</p>
                {Object.values(Tags).map((tag, index) => {
                  return (
                    <button
                      key={`tag-${index}`}
                      disabled={selectedFilter === tag}
                      className="flex items-center p-3 text-xs font-light hover:font-bold text-[#272D31] cursor-pointer transition-all duration-200 disabled:text-[#0C6BAF] disabled:font-bold hover:text-[#0C6BAF] bg-[#F2F5F7] rounded-xl gap-2"
                      onClick={() => {
                        setCurrentPage(1);
                        setSelectedFilter(tag);
                      }}
                    >
                      <div>{selectedFilter === tag && <MdOutlineCheck />}</div>
                      <p className="capitalize text-start">
                        {tag.toLocaleLowerCase()}
                        {tag === "INTERNATIONAL" && (
                          <span className="text-[#8C9AA3] block">
                            non-U.S. member
                          </span>
                        )}
                      </p>
                    </button>
                  );
                })}
                <button
                  disabled={selectedFilter === "NORMAL"}
                  className="flex items-center p-3 text-xs font-light hover:font-bold text-[#272D31] cursor-pointer transition-all duration-200 disabled:text-[#0C6BAF] disabled:font-bold hover:text-[#0C6BAF] bg-[#F2F5F7] rounded-xl gap-2"
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedFilter("NORMAL");
                  }}
                >
                  <div>{selectedFilter === "NORMAL" && <MdOutlineCheck />}</div>
                  <p className="capitalize">{"NONE".toLocaleLowerCase()}</p>
                </button>
              </div>
            </div>
            <div className="p-4 bg-white w-full rounded-[20px]">
              <div className="flex flex-col gap-1">
                <p className="text-black text-[10px] font-bold">
                  Published Date
                </p>
                <InputField
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  dir={DirectionEnum.LTR}
                  value={selectedDate ?? ""}
                  placeholder="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="p-4 bg-white w-full rounded-[20px]">
              <div className="flex flex-col gap-2">
                <p className="text-black text-[10px] font-bold">Categories</p>
                <div className="w-full flex flex-wrap gap-2 items-center">
                  <QuickSearchMenu
                    selectedCategories={selectedCategories}
                    handleSelectCategory={(val) => {
                      setCurrentPage(1);
                      const found = selectedCategories.find((e) => e === val);

                      if (found) {
                        setSelectedCategories(
                          selectedCategories.filter((e) => e !== val)
                        );
                      } else {
                        setSelectedCategories([...selectedCategories, val]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="w-full">
            <div className="flex lg:flex-row flex-col items-start justify-between gap-6">
              <div className="text-[#64748B] font-bold text-sm">
                Result : {totalCount}
              </div>
              {/* SEARCH FIELD */}
              <div className="w-full sm:w-fit flex gap-4 items-center">
                <div className="w-full">
                  <QuickSearchInput
                    value={searchValue}
                    onSearchValueChange={(newSearch) => {
                      setSearchValue(newSearch);
                    }}
                  />
                </div>
                <div className="lg:hidden block">
                  <Button
                    color={ColorsEnum.Muted}
                    variant={VariantsEnum.Muted}
                    size={SizesEnum.Small}
                    onClick={() => setIsModalOpen(true)}
                  >
                    <PiSlidersHorizontalDuotone size={20} />
                  </Button>
                </div>
              </div>
            </div>

            {loading ? (
              <div className="flex justify-center h-full">
                <ClipLoader
                  color={"#0284c7"}
                  loading={loading}
                  aria-label="Loading Spinner"
                  data-testid="loader"
                />
              </div>
            ) : (
              <div>
                {/* CARDS */}
                {cards?.length === 0 ? (
                  <div className="flex items-center flex-col gap-3 justify-center p-20">
                    <img src={placeholderImage} alt="No Result" />
                    <p className="text-center font-bold text-black">
                      No Result ...
                    </p>
                  </div>
                ) : (
                  <div className="flex gap-6 flex-col mb-6">
                    {cards?.map((e) => (
                      <Card
                        key={e.id}
                        data={e}
                        setSelectedId={() => {
                          unstable_batchedUpdates(() => {
                            navigate(`/opportunities/${e.id}`);
                          });
                        }}
                        interested={e.interested}
                      />
                    ))}
                  </div>
                )}
              </div>
            )}
            {!loading && (
              <div
                className={`flex my-16 ${cards?.length === 0 ? "h-full" : ""}`}
              >
                <SharedPagination
                  limit={limit}
                  totalListCount={totalCount}
                  onPageClick={(page: number) => {
                    setCurrentPage(page);
                  }}
                  currentPage={currentPage}
                />
              </div>
            )}
          </div>
        </div>
      </div>

      {/* FOOTER */}
      <Footer />

      {isModalOpen && (
        <Modal size={ModalSizeEnum.MEDIUM}>
          <div className="flex flex-col gap-5 w-full">
            <div className="w-full">
              <div className="flex flex-col gap-1">
                <p className="text-black text-[10px] font-bold">Eligibility</p>
                {Object.values(Tags).map((tag, index) => {
                  return (
                    <button
                      key={`tag-${index}`}
                      disabled={selectedFilter === tag}
                      className="flex items-center p-3 text-xs font-light hover:font-bold text-[#272D31] cursor-pointer transition-all duration-200 disabled:text-[#0C6BAF] disabled:font-bold hover:text-[#0C6BAF] bg-[#F2F5F7] rounded-xl gap-2"
                      onClick={() => {
                        setCurrentPage(1);
                        setSelectedFilter(tag);
                      }}
                    >
                      <div>{selectedFilter === tag && <MdOutlineCheck />}</div>
                      <p className="capitalize">{tag.toLocaleLowerCase()}</p>
                    </button>
                  );
                })}
                <button
                  disabled={selectedFilter === "NORMAL"}
                  className="flex items-center p-3 text-xs font-light hover:font-bold text-[#272D31] cursor-pointer transition-all duration-200 disabled:text-[#0C6BAF] disabled:font-bold hover:text-[#0C6BAF] bg-[#F2F5F7] rounded-xl gap-2"
                  onClick={() => {
                    setCurrentPage(1);
                    setSelectedFilter("NORMAL");
                  }}
                >
                  <div>{selectedFilter === "NORMAL" && <MdOutlineCheck />}</div>
                  <p className="capitalize">{"NONE".toLocaleLowerCase()}</p>
                </button>
              </div>
            </div>

            <div className="w-full">
              <div className="flex flex-col gap-1">
                <p className="text-black text-[10px] font-bold">
                  Published Date
                </p>
                <InputField
                  type="date"
                  onChange={(e) => setSelectedDate(e.target.value)}
                  dir={DirectionEnum.LTR}
                  value={selectedDate ?? ""}
                  placeholder="DD/MM/YYYY"
                />
              </div>
            </div>
            <div className="w-full">
              <div className="flex flex-col gap-2">
                <p className="text-black text-[10px] font-bold">Categories</p>
                <div className="w-full flex flex-wrap gap-2 items-center">
                  <QuickSearchMenu
                    selectedCategories={selectedCategories}
                    handleSelectCategory={(val) => {
                      setCurrentPage(1);
                      const found = selectedCategories.find((e) => e === val);

                      if (found) {
                        setSelectedCategories(
                          selectedCategories.filter((e) => e !== val)
                        );
                      } else {
                        setSelectedCategories([...selectedCategories, val]);
                      }
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="w-full flex justify-between gap-4 items-center">
              <Button
                color={ColorsEnum.Muted}
                variant={VariantsEnum.Muted}
                size={SizesEnum.Medium}
                onClick={() => {
                  setIsModalOpen(false);
                  setSelectedDate("");
                  setSelectedFilter("");
                }}
                className="font-bold !rounded-3xl w-full"
              >
                Cancel
              </Button>
              <Button
                color={ColorsEnum.Success}
                variant={VariantsEnum.Filled}
                size={SizesEnum.Medium}
                onClick={() => {
                  setIsModalOpen(false);
                }}
                className="font-bold !rounded-3xl w-full"
              >
                Apply
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}

export default ArchivedListPage;
