import { useState, useEffect, useCallback } from "react";
import { FundingOpportunity } from "../core/types/types";
import { toast_error } from "../core/hooks/toast_error";
import ClipLoader from "react-spinners/ClipLoader";
import Header from "../components/header/header";
import Card from "../components/card";
import heroImg from "../assets/hero.png";
import logo from "../assets/full-logo.svg";
import getOpImg1 from "../assets/get-op-1.png";
import getOpImg2 from "../assets/get-op-2.png";
import getOpImg3 from "../assets/get-op-3.png";

import {
  AvailabilityEnum,
  ColorsEnum,
  SizesEnum,
  VariantsEnum,
} from "../core/enums/enums";
import { Button } from "../components/button";
import { useNavigate } from "react-router-dom";

import { unstable_batchedUpdates } from "react-dom";
import Footer from "../components/footer";
import { FiChevronRight } from "react-icons/fi";
import { MdOutlineCheck } from "react-icons/md";
import { fetchOpportunitiesService } from "../core/services/shared.service";

function Landing() {
  const [cards, setCards] = useState<FundingOpportunity[]>();
  const [closedCards, setClosedCards] = useState<FundingOpportunity[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  const fetchData = useCallback(async () => {
    setLoading(true);

    try {
      const res = await fetchOpportunitiesService({ limit: 3 });
      setCards(res.result_list);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  const fetchClosedData = useCallback(async () => {
    setLoading(true);

    try {
      const res = await fetchOpportunitiesService({
        limit: 3,
        availability: AvailabilityEnum.CLOSED,
      });
      setClosedCards(res.result_list);
    } catch (error) {
      toast_error(error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchData();
    fetchClosedData();
  }, [fetchClosedData, fetchData]);

  return (
    <div className="h-screen w-screen overflow-x-hidden">
      <div className="min-h-screen max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="h-[600px] lg:max-w-7xl md:max-w-sm max-w-xs w-full mx-auto mb-10 flex lg:flex-row flex-col items-center justify-between gap-10">
          <div className="flex flex-col lg:w-1/3 w-full items-start lg:order-first order-last">
            <img
              src={logo}
              alt="Tornado GI"
              className="h-20 mb-6 w-auto block"
            />
            <p className="text-[#64748B] mb-4 font-bold text-3xl">
              Will help you to find the
            </p>
            <p className="text-[#0E273A] font-bold text-4xl">
              Best Opportunities
            </p>
            <div className="flex lg:flex-row flex-col mt-14 justify-start lg:justify-start gap-6 items-start lg:items-center w-full">
              <Button
                color={ColorsEnum.Muted}
                variant={VariantsEnum.Muted}
                size={SizesEnum.Medium}
                onClick={() => navigate("/opportunities")}
                className="font-bold !rounded-3xl"
                style={{
                  background:
                    "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                  boxShadow:
                    "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
                }}
              >
                <div className="flex items-center text-white gap-1 justify-center">
                  <span>Discover All Opportunities</span>
                  <FiChevronRight />
                </div>
              </Button>
            </div>
          </div>
          <img
            src={heroImg}
            alt="Tornado GI"
            className="lg:w-1/2 w-full lg:order-last order-first"
          />
        </div>

        <div className="sm:h-[400px] min-h-[400px] flex items-center justify-center">
          {loading ? (
            <div className="flex justify-center h-full">
              <ClipLoader
                color={"#0284c7"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="lg:max-w-7xl md:max-w-sm max-w-xs w-full mx-auto mb-auto">
              <div className="flex items-center mb-4 justify-between w-full">
                <p className="text-[#8C9AA3] font-bold text-xl">
                  Latest Opportunities
                </p>

                <Button
                  color={ColorsEnum.Primary}
                  variant={VariantsEnum.None}
                  size={SizesEnum.Medium}
                  onClick={() => navigate("/opportunities")}
                  className="font-bold"
                >
                  <div className="flex items-center gap-1 justify-center">
                    <span>View All</span>
                    <FiChevronRight />
                  </div>
                </Button>
              </div>
              {/* CARDS */}
              {cards?.length === 0 ? (
                <div>
                  <p className="px-20 py-14 text-center">
                    no opportunities yet...
                  </p>
                </div>
              ) : (
                <>
                  <div className="grid gap-6 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mb-6">
                    {cards?.map((e) => (
                      <Card
                        key={e.id}
                        data={e}
                        setSelectedId={() => {
                          unstable_batchedUpdates(() => {
                            navigate(`/opportunities/${e.id}`);
                          });
                        }}
                        interested={e.interested}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>

        <div className="bg-[#0E273A] sm:py-20 py-14 w-full flex items-center justify-center">
          <div className="lg:max-w-7xl md:max-w-sm max-w-xs w-full">
            <div>
              <h4 className="text-xl text-center font-bold mb-10 text-white">
                How to
                <br />
                <span className="text-[#AEB7BD]">
                  Search,Discover and Save an opportunity
                </span>
              </h4>

              <div className="grid lg:grid-cols-3 grid-cols-1 gap-6">
                <div className="w-full px-6 py-8 bg-opacity-10 rounded-xl bg-[#2c9fa7]">
                  <img
                    src={getOpImg1}
                    alt="Browse the list page of Weekly NIH Funding Opportunities"
                    className="w-auto h-48 mb-11 mx-auto"
                  />
                  <div className="flex items-center gap-5">
                    <div className="w-6 h-6 rounded-full flex-shrink-0 flex items-center justify-center bg-[#0BBB87] text-white">
                      <MdOutlineCheck size={12} />
                    </div>
                    <p className="text-white font-bold text-sm">
                      Browse the Grants Opportunities page.
                    </p>
                  </div>
                </div>
                <div className="w-full px-6 py-8 bg-opacity-10 rounded-xl bg-[#2c9fa7]">
                  <img
                    src={getOpImg2}
                    alt="Browse Opportunity details and check if it matches what you want"
                    className="w-auto h-48 mb-11 mx-auto"
                  />
                  <div className="flex items-center gap-5">
                    <div className="w-6 h-6 rounded-full flex-shrink-0 flex items-center justify-center bg-[#0BBB87] text-white">
                      <MdOutlineCheck size={12} />
                    </div>
                    <p className="text-white font-bold text-sm">
                      Check the opportunity details.
                    </p>
                  </div>
                </div>
                <div className="w-full px-6 py-8 bg-opacity-10 rounded-xl bg-[#2c9fa7]">
                  <img
                    src={getOpImg3}
                    alt="Add it to you opportunities interest list"
                    className="w-auto h-48 mb-11 mx-auto"
                  />
                  <div className="flex items-center gap-5">
                    <div className="w-6 h-6 rounded-full flex-shrink-0 flex items-center justify-center bg-[#0BBB87] text-white">
                      <MdOutlineCheck size={12} />
                    </div>
                    <p className="text-white font-bold text-sm">
                      Save it to your opportunities Interested list.
                    </p>
                  </div>
                </div>
              </div>
              <div className="flex items-center justify-center w-full mt-9">
                <Button
                  color={ColorsEnum.Success}
                  variant={VariantsEnum.Filled}
                  size={SizesEnum.Medium}
                  onClick={() => navigate("/opportunities")}
                  className="font-bold !rounded-3xl !bg-success hover:!bg-success-600"
                >
                  <div className="flex items-center text-white gap-1 justify-center">
                    <span>Discover Opportunities List</span>
                    <FiChevronRight />
                  </div>
                </Button>
              </div>
            </div>
          </div>
        </div>

        <div className="min-h-[400px] flex items-center justify-center mt-11">
          {loading ? (
            <div className="flex justify-center h-full">
              <ClipLoader
                color={"#0284c7"}
                loading={loading}
                aria-label="Loading Spinner"
                data-testid="loader"
              />
            </div>
          ) : (
            <div className="lg:max-w-7xl md:max-w-sm max-w-xs w-full mx-auto mb-auto">
              <div className="flex items-center mb-4 justify-between w-full">
                <p className="text-[#8C9AA3] font-bold text-xl">
                  Archived Opportunities
                </p>

                <Button
                  color={ColorsEnum.Primary}
                  variant={VariantsEnum.None}
                  size={SizesEnum.Medium}
                  onClick={() => navigate("/opportunities/archived")}
                  className="font-bold"
                >
                  <div className="flex items-center gap-1 justify-center">
                    <span>View All</span>
                    <FiChevronRight />
                  </div>
                </Button>
              </div>
              {/* CARDS */}
              {closedCards?.length === 0 ? (
                <div>
                  <p className="px-20 py-14 text-center">
                    no opportunities yet...
                  </p>
                </div>
              ) : (
                <>
                  <div className="grid gap-6 xl:grid-cols-3 md:grid-cols-2 sm:grid-cols-1 mb-6">
                    {closedCards?.map((e) => (
                      <Card
                        key={e.id}
                        data={e}
                        setSelectedId={() => {
                          unstable_batchedUpdates(() => {
                            navigate(`/opportunities/${e.id}`);
                          });
                        }}
                        interested={e.interested}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          )}
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Landing;
