import { toast_error } from "../../core/hooks/toast_error";
import { Link, useNavigate } from "react-router-dom";
import {
  ColorsEnum,
  DirectionEnum,
  SizesEnum,
  VariantsEnum,
} from "../../core/enums/enums";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { Button } from "../../components/button";
import { InputField } from "../../components/input-field/input-field";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
import { validationSchema } from "./change-password-container.validation";
import {
  forgetPasswordService,
  updateUserDataService,
} from "../../core/services/shared.service";
import { useState } from "react";
import { PiEye, PiEyeClosed } from "react-icons/pi";
import useUserDataStore, {
  UserDataStoreType,
} from "../../core/stores/user.store";

export default function ChangePasswordContainer() {
  const navigate = useNavigate();
  const { setRefreshUserData, userData }: UserDataStoreType =
    useUserDataStore();
  const [isPasswordVisible, setIsPasswordVisible] = useState<boolean[]>([
    false,
    false,
    false,
  ]);

  const formik = useFormik({
    initialValues: {
      current_password: "",
      new_password: "",
      confirm_new_password: "",
    },
    onSubmit: () => handleSubmit(),
    validationSchema: validationSchema,
    enableReinitialize: true,
    validateOnBlur: true,
  });

  const handleSubmit = async () => {
    try {
      await updateUserDataService({
        current_password: formik.values.current_password,
        new_password: formik.values.new_password,
      });
      toast("Your password has been changed!", {
        style: { backgroundColor: "#38a169", color: "white" },
      });
      setRefreshUserData(true);
      navigate("/user/settings");
    } catch (error) {
      toast_error(error);
    }
  };

  const handleResetPassword = async () => {
    try {
      await forgetPasswordService({ email: userData?.email ?? "" });
      toast("Success, check your email", {
        style: { backgroundColor: "#38a169", color: "white" },
      });
    } catch (error) {
      toast_error(error);
    }
  };

  return (
    <form onSubmit={formik.handleSubmit} className="w-full">
      <div className="lg:w-1/2 mx-auto w-full h-full flex flex-col gap-6 flex-shrink-0">
        <Link
          to="/user/settings"
          className="flex items-center gap-2 text-[#0C6BAF] font-bold text-sm"
        >
          <FiChevronLeft size={20} />
          <span>Back</span>
        </Link>
        <p className="text-black font-bold text-4xl">Change Password</p>
        <div className="p-8 bg-white w-full rounded-[20px] flex flex-col gap-6">
          <InputField
            placeholder="**********"
            aria-label="Current Password"
            type={isPasswordVisible[0] ? "text" : "password"}
            name="current_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[0] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      false,
                      isPasswordVisible[1],
                      isPasswordVisible[2],
                    ])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      true,
                      isPasswordVisible[1],
                      isPasswordVisible[2],
                    ])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.current_password.trimStart() ?? ""}
            isValid={
              formik.touched.current_password && !formik.errors.current_password
            }
            error={
              formik.touched.current_password && formik.errors.current_password
                ? formik.errors.current_password
                : ""
            }
          />
          <div className="flex w-full justify-end">
            <Button
              color={ColorsEnum.Primary}
              variant={VariantsEnum.None}
              type="button"
              onClick={handleResetPassword}
              className="font-bold"
            >
              Forgot Your Password?
            </Button>
          </div>
          <hr />
          <InputField
            placeholder="**********"
            aria-label="New Password"
            type={isPasswordVisible[1] ? "text" : "password"}
            name="new_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[1] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      isPasswordVisible[0],
                      false,
                      isPasswordVisible[2],
                    ])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      isPasswordVisible[0],
                      true,
                      isPasswordVisible[2],
                    ])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.new_password.trimStart() ?? ""}
            isValid={formik.touched.new_password && !formik.errors.new_password}
            error={
              formik.touched.new_password && formik.errors.new_password
                ? formik.errors.new_password
                : ""
            }
          />
          <InputField
            placeholder="**********"
            aria-label="Confirm Password"
            type={isPasswordVisible[2] ? "text" : "password"}
            name="confirm_new_password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            endIcon={
              isPasswordVisible[2] ? (
                <PiEye
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      isPasswordVisible[0],
                      isPasswordVisible[1],
                      false,
                    ])
                  }
                />
              ) : (
                <PiEyeClosed
                  className="cursor-pointer"
                  onClick={() =>
                    setIsPasswordVisible([
                      isPasswordVisible[0],
                      isPasswordVisible[1],
                      true,
                    ])
                  }
                />
              )
            }
            dir={DirectionEnum.LTR}
            value={formik.values.confirm_new_password.trimStart() ?? ""}
            isValid={
              formik.touched.confirm_new_password &&
              !formik.errors.confirm_new_password
            }
            error={
              formik.touched.confirm_new_password &&
              formik.errors.confirm_new_password
                ? formik.errors.confirm_new_password
                : ""
            }
          />

          {/* ACTIONS */}
          <div className="flex justify-end w-full">
            <Button
              color={ColorsEnum.Muted}
              variant={VariantsEnum.Muted}
              size={SizesEnum.Medium}
              type="submit"
              className="font-bold !rounded-3xl"
              style={{
                background: "linear-gradient(270deg, #0C6BAF 0%, #19D0DD 100%)",
                boxShadow:
                  "0px 0px 2px -20px rgba(0, 0, 0, 0.12), 0px 4px 20px 1px rgba(25, 208, 221, 0.70)",
              }}
              disabled={!formik.dirty}
            >
              <div className="flex items-center text-white gap-1 justify-center">
                <span>Reset</span>
                <FiChevronRight />
              </div>
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
}
