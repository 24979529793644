import { MdOutlineCheck } from "react-icons/md";
import useSeedDataStore, {
  SeedDataStoreType,
} from "../../core/stores/seed.store";

interface QuickSearchMenuProps {
  selectedCategories: string[];
  handleSelectCategory: (category: string) => void;
}

export function QuickSearchMenu({
  selectedCategories,
  handleSelectCategory,
}: QuickSearchMenuProps) {
  const { categories }: SeedDataStoreType = useSeedDataStore();

  return (
    <>
      {categories?.map((e, i) => (
        <button
          key={`categories-option-${i}`}
          className={`flex items-center p-3 text-xs hover:font-bold cursor-pointer transition-all duration-200 hover:text-[#0C6BAF] bg-[#F2F5F7] rounded-xl gap-2 ${
            selectedCategories.find((cf) => cf === e.name)
              ? "text-[#0C6BAF] font-bold"
              : "text-[#272D31] font-light"
          }`}
          onClick={() => {
            handleSelectCategory(e.name);
          }}
        >
          {selectedCategories.find((cf) => cf === e.name) && (
            <div>
              <MdOutlineCheck />
            </div>
          )}
          <p className="capitalize">{e.name}</p>
        </button>
      ))}
    </>
  );
}
export default QuickSearchMenu;
