import Header from "../components/header/header";
import Footer from "../components/footer";
import ChangeEmailContainer from "../containers/change-email-container/change-email-container";

export default function ChangeEmailPage() {
  return (
      <div className="h-screen flex flex-col z-50 bg-hero bg-right-top bg-no-repeat bg-contain max-sm:pt-32">
        {/* HEADER */}
        <Header />

        <div className="lg:max-w-7xl md:max-w-sm min-h-[90vh] flex items-center justify-center max-w-xs mx-auto w-full">
          <ChangeEmailContainer />
        </div>
        <Footer />
      </div>
  );
}
