import { useNavigate } from "react-router-dom";
import DropDownMenuButtonItem from "../dropdown/dropdown-menu-button-item";
import { DirectionEnum } from "../../core/enums/enums";

export function PlatformsMenu() {
  const navigate = useNavigate();

  return (
    <div className="flex w-full flex-col items-center gap-2 justify-center">
      <DropDownMenuButtonItem
        label="U.S NIH"
        labelColorClass="text-[#272D31] hover:text-primary"
        action={() => navigate("/opportunities")}
        backgroundColorClass="hover:bg-primary/10"
        dir={DirectionEnum.LTR}
      />
      <DropDownMenuButtonItem
        label="Saudi NIH"
        labelColorClass="text-[#272D31] hover:text-primary"
        action={() => navigate("/opportunities/saudi-nih")}
        backgroundColorClass="hover:bg-primary/10"
        dir={DirectionEnum.LTR}
      />
    </div>
  );
}

export default PlatformsMenu;
