import { AxiosRequestHeaders } from "axios";

import axiosObject from "./config";
import { HttpMethods as httpMethods } from "../enums/httpMethods.enum";
// exporting globally.
export { HttpMethods } from "../enums/httpMethods.enum";
export { urls } from "./urls";
export { axiosObject } from "./config";

export type MakeRequest = {
  url: string;
  method: httpMethods;
  data?: unknown;
  params?: unknown;
  headers?: AxiosRequestHeaders;
  signal?: AbortSignal;
  onUploadProgress?: any;
};

export const makeRequest = async (req: MakeRequest) => {
  const { url, method, data, params, headers, signal, onUploadProgress } = req;

  return axiosObject({
    url,
    method,
    data,
    params,
    headers,
    signal,
    onUploadProgress,
  }).then((res) => {
    return res.data;
  });
};
